import logger, { LEVEL } from '../components/logger';
let myLog;
let providerConfig;

//@TODO This whole module should be refactored - as it simply will not work correctly.
//See todos below for details.

export default function initialize(name, cfg) {
	myLog = logger('TargetingProvider:' + name, cfg.verbosity);
	myLog(LEVEL.FLOW, 'Initializing');

	providerConfig = cfg.targetingProviders[name];

	return {
		attachUnit,
		setPageLevelTargetingAttributes
	};
}


function setPageLevelTargetingAttributes(client) {
	//@TODO We should refactor this here and handleSienaResponse below to use the same code instead
	//of duplicating it like this.
	//@TODO Also, I think something is wrong with the siena request, as there is an error in the console:
	//XMLHttpRequest cannot load http://windstream-gen4.siena1.syn-api.com/v1/targets?content-type=text/javascript&postal_code=. No 'Access-Control-Allow-Origin' header is present on the requested resource. Origin 'http://www.windstream.net' is therefore not allowed access.
	for (let key in providerConfig.data) {
		if (Object.prototype.hasOwnProperty.call(providerConfig.data, key)) {
			myLog(LEVEL.FLOW, `queuing setting targeting attribute "${key}"`);
			googletag.cmd.push(() => {
				googletag.pubads().setTargeting(key, providerConfig.data[key]);
				myLog(LEVEL.INFO, `set targeting attribute "${key}" with value "${providerConfig.data[key]}"`);
			});
		}
	}
	// This is being done the following way only to avoid a portal release. Ideally - this should come from portal.
	//@TODO Clean this up. Send the attribute with the value from portal side instead of hardcoding it
	myLog(LEVEL.FLOW, `queuing setting targeting attribute "portal"`);
	googletag.cmd.push(() => {
		googletag.pubads().setTargeting('portal' , 'gen4');
		myLog(LEVEL.INFO, `set targeting attribute portal with value gen4`);
	});

	googletag.cmd.push(() => {
		googletag.pubads().setTargeting('abt' , 'default');
		myLog(LEVEL.INFO, `set targeting attribute abt with value default`);
	});
}

function attachUnit(unit) {
	// attachUnit is used for setting slot level custom targeting attributes in this case. (only lang as of now).
	if (Object.prototype.hasOwnProperty.call(providerConfig.data, 'lang')) {
		googletag.cmd.push(() => {
			unit.slot.setTargeting('lang', providerConfig.data.lang);
		});
	}
	myLog(LEVEL.INFO, `set targeting attribute lang for the slot "${unit.slot.getAdUnitPath()}" with value "${providerConfig.data.lang}"`);
}

// //@TODO Move all of this ajax crap into a module that we can reuse.
// function sendRequest(url,callback,postData) {
// 	let req = createXMLHTTPObject();
// 	if (!req) return;
// 	let method = (postData) ? 'POST' : 'GET';
// 	req.open(method,url,true);
// 	if (postData) {
// 		req.setRequestHeader('Content-type','application/x-www-form-urlencoded');
// 	}
// 	req.onreadystatechange = function () {
// 		if (req.readyState !== 4) return;
// 		if (req.status !== 200 && req.status !== 304) {
// 			return;
// 		}
// 		callback(req);
// 	};
// 	if (req.readyState === 4) return;
// 	req.send(postData);
// }

// let loadJSONP = (function(){
// 	let unique = 0;
// 	return function(url, callback, context) {
// 		// INIT
// 		let name = '_jsonp_' + unique++;
// 		if (url.match(/\?/)) url += '&callback='+name;
// 		else url += '?callback='+name;
//
// 		// Create script
// 		let script = document.createElement('script');
// 		script.type = 'text/javascript';
// 		script.src = url;
//
// 		// Setup handler
// 		window[name] = function(data){
// 			callback.call((context || window), data);
// 			document.getElementsByTagName('head')[0].removeChild(script);
// 			script = null;
// 			delete window[name];
// 		};
//
// 		// Load JSON
// 		document.getElementsByTagName('head')[0].appendChild(script);
// 	};
// })();

// let XMLHttpFactories = [
// 	function () {return new XMLHttpRequest();},
// 	function () {return new ActiveXObject('Msxml2.XMLHTTP');},
// 	function () {return new ActiveXObject('Msxml3.XMLHTTP');},
// 	function () {return new ActiveXObject('Microsoft.XMLHTTP');}
// ];

// function createXMLHTTPObject() {
// 	let xmlhttp = false;
// 	for (let i=0;i<XMLHttpFactories.length;i++) {
// 		try {
// 			xmlhttp = XMLHttpFactories[i]();
// 		} catch (e) {
// 			continue;
// 		}
// 		break;
// 	}
// 	return xmlhttp;
// }
