import logger, { LEVEL } from '../components/logger';

let myLog;
let provider;
let providerConfig;

export default function initialize(name, cfg) {
	myLog = logger('TargetingProvider:' + name, cfg.verbosity);
	myLog(LEVEL.FLOW, 'Initializing');
	providerConfig = cfg.targetingProviders[name];
	providerConfig.name = name;
	provider = name;
	let scriptUrl = providerConfig.scriptUrl;

	let liveramp = document.createElement('script');
	liveramp.type = 'text/javascript';
	liveramp.id = 'liveramp_loaded';
	liveramp.async = true;
	liveramp.src = scriptUrl;
	document.getElementsByTagName('head')[0].appendChild(liveramp);

	liveramp.onload = function() {
		myLog(LEVEL.FLOW, 'LiveRamp (real-time scanning/blocking for malicious creatives) loaded');
		window.ats.start({
			placementID: parseInt(providerConfig.placementId, 10) || 2101,
			emailHashes: (Array.isArray(providerConfig.emailHashes) && providerConfig.emailHashes.length) ? providerConfig.emailHashes : [],
			logging: 'debug'
		});
		if (window.ats && window.ats.retrieveEnvelope) {
			window.ats.retrieveEnvelope(sendEmailBasedId);
		}
	};

	return {
		attachUnit,
		run
	};
}

function attachUnit() {
}

function run(request) {
	request.handleProviderFinished(provider);
}

function sendEmailBasedId(response) {
	try {
		const responseObj = JSON.parse(response);
		if (!responseObj || !responseObj.envelope) {
			sendCookieBasedId();
			return;
		}
		sendLiverampIdEnvelopeToAdServer(responseObj.envelope);
	} catch (e) {
		myLog(LEVEL.ERROR, 'Error while parsing identity envelope from Liveramp. Using cookie based ID instead.');
		sendCookieBasedId();
	}
}

function sendCookieBasedId() {
	if (window.pbjs) {
		const userIds = window.pbjs.getUserIds();
		if (userIds && userIds.idl_env) {
			sendLiverampIdEnvelopeToAdServer(userIds.idl_env);
		}
	}
}

function sendLiverampIdEnvelopeToAdServer(envelope) {
	let xhr = new XMLHttpRequest();
	const params = 'srv=es&pid=liveramp.com&env=' + envelope;
	xhr.open('POST', 'https://uat-net.technoratimedia.com/services', true);
	xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
	xhr.withCredentials = true;
	xhr.send(params);
}
