const FIRST_PARTY_USER_ID_COOKIE_NAME = 'pubUID';
const FIRST_PARTY_USER_ID_COOKIE_EXPIRES_AFTER = 7 * 24 * 60 * 60 * 1000; // 1 week in milliseconds

/**
 * NOTE: make sure that you are checking for CCPA opt-outs and any other
 * privacy related settings (`window.__uspapi` or `window.__gpp` APIs)
 * before using this.
 *
 * @returns {string|null} A string containing the first party user id
 */
export function getFirstPartyUserId() {
	let cookie = getCookie({ name: FIRST_PARTY_USER_ID_COOKIE_NAME });
	if (cookie !== null) {
		return cookie.value;
	}

	// FIXME: We should use `crypto.randomUUID()` when it is available
	//        across all of our supported browsers (i.e., after the next
	//        major release of Safari happens).
	const userId = createRandomId();
	const expires = new Date(new Date().getTime() + FIRST_PARTY_USER_ID_COOKIE_EXPIRES_AFTER).toUTCString();
	setCookie({ name: FIRST_PARTY_USER_ID_COOKIE_NAME, value: userId, expires });

	return userId;
}

/**
 * @typedef {object} CookieInfo
 * @property {string} name
 * @property {string} value
 */

/**
 * @param {object} cookie
 * @param {string} cookie.name
 * @returns {CookieInfo|null}
 */
function getCookie({ name }) {
	let match = document.cookie.match(new RegExp('(?:^|;)\\s*' + name + '=((?:[^;]|$)*)'));
	if (match !== null) {
		return { name, value: match[1] };
	}
	return null;
}

/**
 * @param {object} cookie
 * @param {string} cookie.name
 * @param {string} cookie.value
 * @param {string} [cookie.expires]
 */
function setCookie({ name, value, expires }) {
	let cookie = `${name}=${value};path=/`;
	if (typeof expires !== 'undefined') {
		cookie += `; expires=${expires}`;
	}
	document.cookie = cookie;
}


/**
 * Creates a hex encoded string containing 128 random bits
 * @returns {string}
 */
function createRandomId() {
	const randomBytes = Array.from(crypto.getRandomValues(new Uint8Array(16)));
	const hexEncodedBytes = randomBytes.map(byte => byte.toString(16).padStart(2, '0'));
	return hexEncodedBytes.join('');
}
