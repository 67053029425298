export default class TrackingModule {

	constructor( config ){
		this.config = config;
	}

	// This function should be implemented by each subclass
	track( options ){
		return null;
	}

	// Determine if tracking can actually be done
	permitsTracking( overrideThrottle ){
		return this.isLoaded() && this.isEnabled() && ( !this.isThrottled() || overrideThrottle );
	}

	// Determines if the tracking module is loaded and ready
	isLoaded(){
		return true;
	}

	// Determine if the tracking module is currently enabled
	isEnabled(){
		return true;
	}

	// Determine if the tracking module is currently being throttled for this instance
	isThrottled(){
		return false;
	}
}
