/* eslint-disable no-console */

import logger from 'debug';

/**
 * Notes about verbosity:
 * When calling the returned method from this module, you can optionally
 * specify the importance level as the first argument with an integer between 0 and 5.
 * 0 === Least important (debugging)
 * 1 === (runtime flow)
 * 2 === (standard information) This is the default level if not specified.
 * 3 === (configuration)
 * 4 === (warnings) (These typically are the 'info' and 'warning' calls which log no matter what.)
 * 5 === Most important (exceptions) (These typically are the 'error' calls which log no matter what.)
 * When the verbosity is set, it can be 0 through 5.
 * 0 would show any log messages with level 5.
 * 5 would show any log messages with level 0 and up.
 * This module also exports a level object as a constant. Those can be used in place of integers.
 */

window.Sadlib_Config = window.Sadlib_Config || {};
window.Sadlib_Config.startTime = window.Sadlib_Config.startTime || Date.now();
window.Sadlib_Config.savedLog = {
	ordered: [],
	namespaced: {}
};

const SAVE_LOG_REGEX = /sadlib_save_log=1/;
const SAVE_LOG = !!(SAVE_LOG_REGEX.test(window.location.href));
const SEGMENT_LOG_REGEX = /sadlib_segment_log=1/;
const SEGMENT_LOG = !!(SEGMENT_LOG_REGEX.test(window.location.href));

let lastCall = Date.now();
if (SEGMENT_LOG) {
	console.groupCollapsed(Date.now() - window.Sadlib_Config.startTime + 'ms');
}

export const LEVEL = {
	DEBUG: 0,
	FLOW: 1,
	INFO: 2,
	CONFIG: 3,
	WARN: 4,
	ERROR: 5
};

export default function createDebug(namespace, verbosity) {
	verbosity = (typeof verbosity === 'number') ? verbosity : 0;
	namespace = namespace || 'main';
	let myDbg = logger('sadlib:' + namespace);
	if (SAVE_LOG) {
		window.Sadlib_Config.savedLog.namespaced[namespace] = [];
	}

	return function (...args) {
		if (SEGMENT_LOG) {
			if (Date.now() - lastCall > 100) {
				console.groupEnd();
				console.groupCollapsed(Date.now() - window.Sadlib_Config.startTime + 'ms');
			}
			lastCall = Date.now();
		}

		args.push(Date.now() - window.Sadlib_Config.startTime + 'ms');
		if (window.Sadlib_Config.document_start_time) {
			args.push(Date.now() - window.Sadlib_Config.document_start_time + 'ms');
		}
		if (SAVE_LOG) {
			//This code is not executed unless SAVE_LOG is enabled.
			//This converts all objects and functions to strings so
			//that we can json encode it without running into
			//circular references.
			let basicArgs = [];
			for (let i of args) {
				let newArg;
				if (typeof args[i] === 'object') {
					newArg = '[object]';
				} else if (typeof args[i] === 'function') {
					newArg = '[function]';
				} else {
					newArg = args[i];
				}
				basicArgs.push(newArg);
			}
			window.Sadlib_Config.savedLog.ordered.push([namespace, basicArgs]);
			window.Sadlib_Config.savedLog.namespaced[namespace].push(basicArgs);
		}
		let importance = 2;
		if (typeof args[0] === 'number') {
			importance = args.shift();
		}
		if (importance >= (5 - verbosity)) {
			myDbg.apply(this, args);
		}
		//If this is an important message, log it no matter what
		if (console && (importance >= 4)) {
			try {
				console[args[0]].apply(window, args);
			} catch (e) {
				console.error(args);
			}
		}
	};
}
