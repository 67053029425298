import merge from 'deepmerge';
import logger, { LEVEL } from '../../src/lib/components/logger';
import Tracker from '../../src/lib/components/Tracker';
let device = 'desktop';
let pageConfigPath = '';
let pageConfigOverridesPath = '';
let defaultConfigOverridePath = '';
let pageConfig = {};
let pageConfigOverrides = {};
let defaultConfigOverrides = {};

export default class ClientCode {

	/**
	 * Main Constructor.
	 * @param config
	 */
	constructor(config, logMsg) {
		this.config = config;
		this.myLog = logger('ClientCode', this.config.verbosity);
		this.tracker = new Tracker(config);
		this.myLog(LEVEL.FLOW, logMsg || 'synacor custom js loaded');
	}

	/**
	 * Builds a standard output object
	 * @param config
	 * @returns {*}
	 */
	parseAdUnitConfig(unit) {
		let output = {
			status: !(unit && unit.disabled),
			ad_refresh: (unit && unit.ad_refresh) ? unit.ad_refresh : 0,
			smartRefresh: !!((unit?.smartRefresh)),
			smartRefreshForceEnabled: !!((unit?.smartRefreshForceEnabled)),
			smartRefreshForceEvery: unit?.smartRefreshForceEvery,
			smartRefreshStopEnabled: !!((unit?.smartRefreshStopEnabled)),
			smartRefreshStopAfter: unit?.smartRefreshStopAfter,
			smart_refresh_ms: (unit && unit.smart_refresh_interval) ? unit.smart_refresh_interval : 120,
			lazyLoad: !!((unit?.lazyLoad)),
			collapseBeforeFetch: !!((unit?.collapseBeforeFetch)),
			floors: (unit?.floors) ? unit.floors : ''
		};
		return output;
	}

	/**
	 * @param unit
	 * @returns {*}
	 */
	getUnitConfig(unit) {
		if (!unit) {
			return this.parseAdUnitConfig();
		}
		return this.parseAdUnitConfig(unit);
	}

	/**
	 * Set custom unit stuff.
	 * This sets the refresh value, close button, and unit path.
	 * @param config
	 * @param unit
	 * @param unitConfig
	 * @param device
	 * @returns {*}
	 */
	setCustomValues (config, unit, unitConfig, device) {
		unit.refresh_ms = unitConfig.ad_refresh * 1000;
		unit.smart_refresh_ms = unitConfig.smart_refresh_ms * 1000;
		unit.smartRefresh = unitConfig.smartRefresh;
		unit.smartRefreshForceEnabled = unitConfig.smartRefreshForceEnabled;
		unit.smartRefreshForceEvery = unitConfig.smartRefreshForceEvery;
		unit.smartRefreshStopEnabled = unitConfig.smartRefreshStopEnabled;
		unit.smartRefreshStopAfter = unitConfig.smartRefreshStopAfter;
		unit.lazyLoad = unitConfig.lazyLoad;
		unit.collapseBeforeFetch = unitConfig.collapseBeforeFetch;
		unit.floors = unitConfig.floors;

		if (unitConfig.type) {
			unit.type = unitConfig.type;
		}
		device = device || 'desktop';

		let dfpClientName = unit.productType ? (config.dfp_client_name + unit.productType) : config.dfp_client_name;
		if (config.dfp_child_network_id) {
			unit.path = '/' + (config.dfp_network_id || '') + ',' + config.dfp_child_network_id + '/' + (dfpClientName || '') + '/' + unit.path;
		} else {
			unit.path = '/' + (config.dfp_network_id || '') + '/' + (dfpClientName || '') + '/' + unit.path;
		}

		return unit;
	}

	mergeUnits(destUnits, srcUnits) {
		destUnits.forEach(destItem => {
			const matchingUnit = srcUnits.find(srcItem => srcItem.name === destItem.name);
			if (matchingUnit) Object.assign(destItem, matchingUnit);
		  });
	}

	/**
	 * Mutate the configuration objects as needed for this client.
	 * The 'includeAllJson' here lets us not bundle all site json files in the build. The build-client.sh script handles that for some clients (frankly_media).
	 */
	mutateConfig(config, includeAllJson = true) {

		//Merge the window.Sadlib_Config object over the config.json object
		config = merge(config, (window.Sadlib_Config || {}));

		//Set the default config path for the current page
		pageConfigPath = './pages/' + config.page_type + '.json';
		pageConfigOverridesPath = './' + config.siteId + '/' + config.page_type + '.json';
		defaultConfigOverridePath = './' + config.siteId + '/config.json' ;

		try {
			let context;
			let configFiles = {};
			try {
				if (includeAllJson) { //If we use this variable in the require.context call, webpack gives us errors about static compilation.
					context = require.context('./', true, /\.(json)$/);
				} else {
					context = require.context('./', false, /\.(json)$/);
				}
				context.keys().forEach((filename) => {
					configFiles[filename] = context(filename);
				});
			} catch (e) {
				console.error(e);
			}
			if (config.page_type && Object.prototype.hasOwnProperty.call(configFiles, pageConfigPath)) { pageConfig = configFiles[pageConfigPath]; }
			if (config.siteId && Object.prototype.hasOwnProperty.call(configFiles, pageConfigOverridesPath)) { pageConfigOverrides = configFiles[pageConfigOverridesPath]; }
			if (config.siteId && Object.prototype.hasOwnProperty.call(configFiles, defaultConfigOverridePath)) { defaultConfigOverrides = configFiles[defaultConfigOverridePath]; }
		} catch (e) {
			this.myLog('error' , 'Error while trying to fetch site specific configurations');
		}

		//Merge the site level overrides for all the configuration except the ad units (includeing targeting providers, timeouts etc.)
		if (defaultConfigOverrides) { config = merge(config, (defaultConfigOverrides || {})); }

		if (window.Sadlib_Config && window.Sadlib_Config.device_type) {
			device = window.Sadlib_Config.device_type;
		}

		this.myLog(LEVEL.INFO, 'Using ' + device + ' ad units.');
		const unitsSection = device + '_units';
		config.units = pageConfig[unitsSection] || [];
		if (pageConfigOverrides && pageConfigOverrides[unitsSection]) { pageConfigOverrides.units = pageConfigOverrides[unitsSection] || []; }

		if (pageConfigOverrides) {
			if (pageConfigOverrides.units && pageConfigOverrides.units.length > 0) {
				this.mergeUnits(config.units, pageConfigOverrides.units);
				pageConfigOverrides.units = [];
			}
			config = merge(config, (pageConfigOverrides || {}));
		}

		//Merge in localStorage override if it exists
		if (localStorage && localStorage.Sadlib_Config && localStorage.Sadlib_Config.length) {
			try {
				let lsConfig = JSON.parse(localStorage.Sadlib_Config);
				config = merge(config, (lsConfig || {}));
			} catch (ignored) {}
		}

		//Filter any units, then map the path to the correct format.
		//This uses reduce because that is less function calls.
		config.units = config.units.reduce((memo, unit) => {
			let unitConfig = this.getUnitConfig(unit);
			if (unitConfig.status === true && !this.aboveIncLimit(unitConfig, unit.path)) {

				// Remove any adhesion if disabled by client for device
				if (unit.name === 'adhesion') {
					if (config.disableAdhesion && config.disableAdhesion[device]) {
						return memo;
					}
				}

				// Add unit if there is at least one supported size
				if ( unit.sizes.length > 0) {
					unit = this.setCustomValues(config, unit, unitConfig, device);
					memo.push(unit);
				} else {
					this.myLog(LEVEL.CONFIG, `Removing ${unit.path} because none of its sizes fit.` );
				}
			} else if (!this.aboveIncLimit(unitConfig, unit.path)){
				this.myLog(LEVEL.INFO, `Removing ${unit.path} because it is disabled.`);
			}
			return memo;
		}, []);

		return config;
	}

	/**
	 * Some units can be disabled above a certain inc (refresh) value that gets set as a url query string param
	 * The upper bound if present is specified as a setting which is unit level
	 * Not currently enabled for any ad (9/5/2023)
	 * @param unitConfig This contains the settings for the unit
	 * @param unit path of the unit for logging purposes
	 * @returns {boolean}
	 */
	aboveIncLimit(unitConfig, unit) {
		if (unitConfig.inc_limit) {
			let incUpperLimit = unitConfig.inc_limit;
			let incCurrentValue = window.location.href.match(/inc=[0-9]+/);
			incCurrentValue = (incCurrentValue && incCurrentValue.length) ? parseInt(incCurrentValue[0].substring(4), 10) : null;
			if (incCurrentValue > incUpperLimit) {
				this.myLog(LEVEL.INFO, `Removing ${unit} because the inc value ${incCurrentValue} is above the allowed value ${incUpperLimit}.`);
				return true;
			}
		}
		return false;
	}

	/**
	 * Add 3rd party code to the page. This is unrelated to ad serving and is added here due to ease of injecting code into the portal.
	 */
	applyCustomThirdPartyCode(config) {

		/* Pubmatic IdentityHub for Prebid --- START */
		let purl = window.location.href,
			url = '//ads.pubmatic.com/AdServer/js/pwt/48110/10811',
			profileVersionId = '';

		if (purl.indexOf('pwtv=') > 0) {
			let regexp = /pwtv=(.*?)(&|$)/g,
				matches = regexp.exec(purl);
			if (matches.length >= 2 && matches[1].length > 0) {
				profileVersionId = '/' + matches[1];
			}
		}

		// Pubmatic supplied example did not have this wrapped in check for profileVersionId
		// but added because otherwise it tried to load an invalid script url
		if (profileVersionId) {
			let wtads = document.createElement('script');
			wtads.async = true;
			wtads.type = 'text/javascript';
			wtads.src = url+profileVersionId+'/pwt.js';
			let node = document.getElementsByTagName('script')[0];
			node.parentNode.insertBefore(wtads, node);
		}

		/* Pubmatic IdentityHub for Prebid --- END */

	}
}
