import logger, { LEVEL } from '../components/logger';

let myLog;
let provider;
let providerConfig;

export default function initialize(name, cfg) {
	myLog = logger('TargetingProvider:' + name, cfg.verbosity);
	myLog(LEVEL.FLOW, 'Initializing');
	providerConfig = cfg.targetingProviders[name];
	providerConfig.name = name;
	provider = name;
	let script_url = providerConfig.script_url;
	window.grumi = {
		key: providerConfig.key
	};

	let geoedge = document.createElement('script');
	geoedge.type = 'text/javascript';
	geoedge.id = 'geoedge_loaded';
	geoedge.async = true;
	geoedge.src = script_url;
	geoedge.onload = function () {
		myLog(LEVEL.FLOW, 'GeoEdge (real-time scanning/blocking for malicious creatives) loaded');
	};
	document.getElementsByTagName('head')[0].appendChild(geoedge);

	return {
		attachUnit,
		run
	};
}

function attachUnit() {
}

function run(request) {
	request.handleProviderFinished(provider);
}
