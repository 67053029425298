import logger, { LEVEL } from '../components/logger';
import connectId from '@yahoo/yahoo-connectid';

let myLog;
let provider;
let providerConfig;

export default function initialize(name, cfg) {
	myLog = logger('TargetingProvider:' + name, cfg.verbosity);
	myLog(LEVEL.FLOW, 'Initializing');
	providerConfig = cfg.targetingProviders[name];
	providerConfig.name = name;
	provider = name;
	myLog(LEVEL.FLOW, 'Verizon Connect ID loaded');

	const params = {
		pixelId: parseInt(providerConfig.pixelId, 10) || 58489,
		email: providerConfig.emailHash || '',
		gdpr: false
	};

	if (connectId && connectId.getIds) {
		connectId.getIds(params, processIDs);
	}

	return {
		run
	};
}

function processIDs(ids) {
	// Param ids is a map of id type to value, for example {connectid: 'abc123'}. Pass ids.connectid in request to ad server
	if (ids && ids.connectid) {
		let xhr = new XMLHttpRequest();
		const params = 'srv=vs&pid=verizonmedia.com&verizonConnectId=' + ids.connectid;
		xhr.open('POST', 'https://uat-net.technoratimedia.com/services', true);
		xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
		xhr.withCredentials = true;
		xhr.send(params);
	}
}

function run(request) {
	request.handleProviderFinished(provider);
}
