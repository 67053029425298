import logger, { LEVEL } from './logger';
import TrackingPixel from '../tracking_modules/TrackingPixel';

export default class Tracker {

	constructor(config) {

		this.config = config;
		this.myLog = logger('Tracker', this.config.verbosity);
		this.config.tracking = this.config.tracking || {};
		this.config.tracking.modules = this.config.tracking.modules || {};

		if ( typeof this.config.tracking.modules.defaults === 'string' ){
			this.defaultModules = this.config.tracking.modules.defaults.split( ',' );
		} else {
			this.defaultModules = [ 'TrackingPixel' ];
		}

		// TODO: Populate the tracking modules dynamically
		this.trackingModules = {};
		this.trackingModules.TrackingPixel = new TrackingPixel( this.config );
	}

	track( options ){

		options = options || {};
		let modules, tracker, results, primaryResults;

		// Get the list of modules to use for tracking
		if ( Array.isArray( options.modules )){
			modules = options.modules;
		} else if ( typeof options.modules === 'string' && options.modules !== '' ){
			modules = options.modules.split( ',' );
		} else {
			modules = this.defaultModules;
		}
		delete options.modules;

		// Track using each specified module
		for ( let i = 0; i < modules.length; i++ ){

			tracker = this.trackingModules[modules[i]];
			if ( !tracker ){
				this.myLog(LEVEL.WARN, `WARNING: '${modules[i]}' is not a valid tracking module` );
				continue;
			}

			try {
				if ( tracker.permitsTracking( !!options.overrideThrottle )){
					results = tracker.track( options );
				}
			} catch ( error ){
				this.myLog(LEVEL.WARN, `WARNING: Unable to track using ${modules[i]} due to error:`, error );
			}

			if ( i === 0 ){ primaryResults = results; }
		}

		return primaryResults;
	}
}
