import once from './myArrive';
import AdUnitClose from './AdUnitClose';
import logger, { LEVEL } from './logger';
import { isDocumentInFocus } from './focusUtils';

const DEFAULT_SMART_REFRESH_FORCE_EVERY = 6;
const DEFAULT_SMART_REFRESH_STOP_AFTER = 10;
const DEFAULT_LAZY_LOAD_DELAY = 0;

/**
 * An enum representing the reason why a given ad unit is being
 * refreshed.
 * @readonly
 * @enum {string}
 */
const RefreshType = {

	/**
	 * The ad unit is being refreshed because smart refresh's
	 * viewability checks passed.
	 */
	SMART: 'smart',

	/**
	 * Ad unit is being refreshed because of its `smartRefreshForce*`
	 * settings (which are used to emulate a "dumb" timed refresh)
	 */
	SMART_FORCED: 'smart_forced',

	/** The ad unit is being refreshed because of "dumb" timed refresh */
	TIMED: 'timed'
};

/**
 * An enum containing the GAM targeting attributes used by our refresh
 * mechanisms.
 * @readonly
 * @enum {string}
 */
const GamTargetingAttribute = {

	/**
	 * GAM targeting attribute for refreshes triggered by smart refresh
	 * with a passing viewability check
	 */
	SMART_REFRESH: 'sr',

	/**
	 * GAM targeting attribute for refreshes triggered by timed refresh
	 * and `smartRefreshForce*`
	 */
	TIMED_REFRESH: 'ar'
};

/**
 * Maps a {@link RefreshType} to its corresponding
 * {@link GamTargetingAttribute}
 */
function targetingAttributeForType(type) {
	switch (type) {
		case RefreshType.SMART:
			return GamTargetingAttribute.SMART_REFRESH;
		case RefreshType.SMART_FORCED:
		case RefreshType.TIMED:
		default:
			return GamTargetingAttribute.TIMED_REFRESH;
	}
}

export default class AdUnit {
	constructor(config, sadlibConfig, adUnits) {
		//Setup configuration
		this.adUnits = adUnits;
		this.config = config;
		this.sadlibConfig = sadlibConfig;
		this.currentTimedRefreshCount = 0;

		/**
		 * @type {number}
		 */
		this.successfulSmartRefreshCount = 0;

		/**
		 * @type {number}
		 */
		this.attemptedSmartRefreshCount = 0;

		/**
		 * Number of successive smart refresh attempts where the ad unit
		 * was not viewable. This resets after smart refresh attempts
		 * that succeed because the ad unit is viewable (but does not
		 * reset for attempts where the refresh is forced despite being
		 * non-viewable).
		 * @type {number}
		 */
		this.smartRefreshNonViewableAttemptCount = 0;

		this.config.collapseEmptyDiv = (typeof this.config.collapseEmptyDiv !== 'undefined') ? this.config.collapseEmptyDiv : true;
		this.config.collapseBeforeFetch = (typeof this.config.collapseBeforeFetch !== 'undefined') ? this.config.collapseBeforeFetch : false;
		this.divid = ('div-gpt-ad-' + this.config.name).replace(/\/|\./g, '_');
		this.myLog = logger('AdUnit:' + this.config.path, this.sadlibConfig.verbosity);
		this.myElmt = null;
		this.viewability = {
			boundingClientRect: undefined,
			intersectionRatio: undefined,
			intersectionRect: undefined,
			isIntersecting: undefined,
			rootBounds: undefined,
			target: undefined,
			time: undefined
		};
		this.viewabilityObserver;
		this.impressionViewable = false;
		this.targetingProviders;

		// Setup rendering routine
		this.selector = null;
		this.insertMethod = null;

		//Figure out selector method to use.
		if ( this.config.parentSelector && this.config.parentSelector !== '' ){
			this.selector = this.config.parentSelector;
			this.insertMethod = this.insertChild;
		} else if ( this.config.nextSiblingSelector && this.config.nextSiblingSelector !== '' ){
			this.selector = this.config.nextSiblingSelector;
			this.insertMethod = this.insertBefore;
		} else if ( this.config.previousSiblingSelector && this.config.previousSiblingSelector !== '' ){
			this.selector = this.config.previousSiblingSelector;
			this.insertMethod = this.insertAfter;
		} else if ( this.config.predecessorSelector && this.config.predecessorSelector !== '' ){
			this.selector = this.config.predecessorSelector;
			this.insertMethod = this.replaceElement;
		}

		// Setup refresh parameters

		if (this.smartRefresh && this.config.refresh_ms) {
			this.myLog(LEVEL.WARN, `Both smart refresh and timed refresh are enabled for ${this.config.name}`);
		}

		/** @type {boolean} */
		this.lazyLoad = !!this.config?.lazyLoad;

		/** @type {boolean} */
		this.smartRefresh = !!this.config?.smartRefresh;

		/**
		 * When `true`, after smart refresh fails to refresh the ad unit
		 * a given number of times because of failed visiblitiy checks
		 * it will then ignore those checks on the next attempt and
		 * refresh the ad unit anyways.
		 * @type {boolean}
		 */
		this.smartRefreshForceEnabled = !!this.config?.smartRefreshForceEnabled;

		/**
		 * A non-negative integer. If `smartRefreshForceEnabled` is
		 * `true`, after this number of subsequent refresh attempts fail
		 * visibility checks smart refresh will ignore its visibility
		 * checks and refresh the ad unit on its next attempt.
		 * @type {number}
		 */
		this.smartRefreshForceEvery = typeof this.config?.smartRefreshForceEvery !== 'undefined' && this.config?.smartRefreshForceEvery !== null ? this.config.smartRefreshForceEvery : DEFAULT_SMART_REFRESH_FORCE_EVERY;
		// sanity check
		if (this.smartRefreshForceEvery <= 0) {
			this.myLog(LEVEL.WARN, `${this.config.name} has smartRefreshForceEvery set to an invalid value (${this.smartRefreshForceEvery}), setting smartRefreshForceEnabled to false`);
			this.smartRefreshForceEnabled = false;
		}

		/**
		 * When `true`, smart refresh will stop running if a given
		 * number of subsequent refresh attempts "fail" due to their
		 * visibility checks failing.
		 * @type {boolean}
		 */
		this.smartRefreshStopEnabled = !!this.config?.smartRefreshStopEnabled;

		/**
		 * A non-negative integer greater than `1`. If
		 * `smartRefreshStopEnabled` is `true`, smart refresh will
		 * stop attempting to refresh the ad unit after this number of
		 * "failed" refresh attempts.
		 * @type {number}
		 */
		this.smartRefreshStopAfter = typeof this.config?.smartRefreshStopAfter !== 'undefined' && this.config?.smartRefreshStopAfter !== null ? this.config.smartRefreshStopAfter : DEFAULT_SMART_REFRESH_STOP_AFTER;
		// sanity check. if the setting doesn't make sense disable it
		if (this.smartRefreshStopAfter <= 0) {
			this.myLog(LEVEL.WARN, `${this.config.name} has smartRefreshStopAfter set to an invalid value (${this.smartRefreshStopAfter}), setting smartRefreshStopEnabled to false`);
			this.smartRefreshStopEnabled = false;
		}
	}

	setup(targetingProviders) {
		if (!this.selector || !this.insertMethod) {
			this.myLog(LEVEL.WARN, `No selector or insertMethod specified.`);
			return;
		}
		this.targetingProviders = targetingProviders;

		this.insertStyles();

		//If lazyload, don't add to gpt yet
		if (this.lazyLoad) {
			let llDelay = (typeof this.config.lazyLoadDelay !== 'undefined') ? parseInt(this.config.lazyLoadDelay, 10) : DEFAULT_LAZY_LOAD_DELAY;
			this.myLog(LEVEL.CONFIG, `${this.config.name} is configured to lazy load with a delay of ${llDelay}ms.`);
			//We MUST define this here, or else we can't remove these listeners
			this.listener = () => {
				this.scrollLLHandler();
			};
			setTimeout(() => {
				this.waitForElement(() => {
					this.scrollLLHandler();
					this.setupLLScrollHandlers();
				});
			}, llDelay);
			return;
		}
		this.waitForElement();
		//Normal unit init here
		this.addToGpt(this.targetingProviders.attachUnit(this.config.name), false);
	}

	insertStyles() {
		//If unit has a stylesheet defined, add it to the style block in the head
		if (this.config.styles && this.config.styleBlock) {
			if (this.config.styleBlock.styleSheet) {
				this.config.styleBlock.styleSheet.cssText += this.config.styles;
			} else {
				this.config.styleBlock.appendChild(document.createTextNode(this.config.styles));
			}
			this.myLog(LEVEL.FLOW, 'Added styles to style block.');
		}
	}

	waitForElement(cb) {
		//Wait for selector to match, then render the div.
		this.myLog(LEVEL.FLOW, `waiting for selected element: ${ this.selector }`);
		let self = this;
		once(this.selector, function() { //Used old format here in order to preserve 'this'
			//'this' is the element
			self.myLog(LEVEL.DEBUG, `selected element found: ${ self.selector }`);
			self.render(this, self.insertMethod);
			if (cb) {
				cb.apply(self);
			}
		});
	}

	/**
	 * Sets up a Google Publisher Tag (GPT) slot for this ad unit.
	 */
	buildSlot() {
		this.config.slot = googletag.defineSlot(this.config.path, this.config.sizes, this.divid);
		if (this.config && this.config.customSizeMapping && this.config.customSizeMapping.length ) {
			// Custom size mapping is used to define different slot sizes at various breakpoints.
			// slot.defineSizeMapping will receive a 2D array of mappings in the following form:
			// [[ viewport_width, viewport_height], [[size_1_width, size_1_height], [size_2_width, size_2_height]]], [[viewport_width, viewport_height], [size_width, size_1_height]]....]
			// viewport resolution 0x0 represents the ad size that can fit in any resolution. Preference is always given to the highest possible resolution (width preferred over height)
			// Refer to https://support.google.com/admanager/answer/3423562 for more details.
			this.config.slot.defineSizeMapping(this.config.customSizeMapping);
		}
		this.config.slot.addService(googletag.pubads());
		this.config.slot.addService(googletag.companionAds());
		this.myLog(LEVEL.INFO, `set collapse empty div:`, this.config.collapseEmptyDiv);
		this.myLog(LEVEL.INFO, `set collapse before fetch:`, this.config.collapseBeforeFetch);
		this.config.slot.setCollapseEmptyDiv(this.config.collapseEmptyDiv, this.config.collapseBeforeFetch);
		this.myLog(LEVEL.FLOW, `added to gpt`);
	}

	applyCustomTargeting() {
		if (this.config && Array.isArray(this.config.customTargeting) && this.config.customTargeting.length) {
			this.config.customTargeting.forEach((targetingKV) => {
				for (let [key, value] of Object.entries(targetingKV)) {
					if (Object.prototype.hasOwnProperty.call(targetingKV, key)) {
						this.config.slot.setTargeting(key, value);
					}
				}
			});
		}
		// For the initial load, set 'ar' targeting (ad refresh) to 0
		this.config.slot.setTargeting(GamTargetingAttribute.TIMED_REFRESH, '0');
	}

	addToGpt(cb, finalizeSetup) {
		//Add this unit to gpt (defineSlot).
		googletag.cmd.push(() => {
			this.buildSlot();

			this.applyCustomTargeting();

			// Slot is defined, call callback to initialize targeting providers and get refresh callback
			this.refreshTargetingProviders = cb(this); //eslint-disable-line callback-return

			// Setup refreshing
			this.setupTimedRefresh(true);

			if (finalizeSetup) {
				this.finalizeSetup();
			}
		});
		this.myLog(LEVEL.FLOW, `queued in gpt`);
	}

	setupLLScrollHandlers(remove) {
		//Setup scroll listeners
		let parentElements = this.getParents(this.adElmt);
		for (let i in parentElements) {
			if (Object.prototype.hasOwnProperty.call(parentElements, i)) {
				try {
					if (remove !== true) {
						parentElements[i].addEventListener('scroll', this.listener, false);
					} else {
						parentElements[i].removeEventListener('scroll', this.listener, false);
					}
				} catch (ignored) {}
			}
		}
	}

	scrollLLHandler() {
		requestAnimationFrame(() => {
			if (!this.config.slot && this.isElementInViewport(this.adElmt)) {
				this.setupLLScrollHandlers(true);
				if (!this.targetingProviders.initialRequest.allFinished) {
					this.addToGpt(this.targetingProviders.attachUnit(this.config.name), false);
				} else {
					this.addToGpt(this.targetingProviders.attachAndRefresh(this, () => {
						this.finalizeSetup();
					}), false);
				}
			}
		});
	}

	setupTimedRefresh(initialLoad, event){
		if (this.config.refresh_ms && this.config.refresh_ms > 0 && !this.refresh_interval) {
			this.myLog(LEVEL.INFO, `Refreshing ${this.config.name} in ${this.config.refresh_ms}ms.`);
			this.refresh_interval = setInterval(() => {
				this.myLog(LEVEL.INFO, `Trying to timed refresh ${this.config.name} because of timer.`);
				this.doRefresh();
			}, this.config.refresh_ms);
		}
		if (this.smartRefresh && this.config.smart_refresh_ms && this.config.smart_refresh_ms > 0 && !this.smart_refresh_interval) {
			this.myLog(LEVEL.INFO, `Smart refreshing ${this.config.name} in ${this.config.smart_refresh_ms}ms.`);
			this.smart_refresh_interval = setInterval(() => {
				this.myLog(LEVEL.INFO, `Trying to smart refresh ${this.config.name} because of timer.`);
				this.doSmartRefresh();
			}, this.config.smart_refresh_ms);
		}
	}

	_smartRefreshShouldForceRefresh() {
		if (!this.smartRefreshForceEnabled) {
			return false;
		}
		return this.attemptedSmartRefreshCount % this.smartRefreshForceEvery === 0;
	}

	_isSmartRefreshStopped() {
		if (!this.smartRefreshStopEnabled) {
			return false;
		}
		return this.smartRefreshNonViewableAttemptCount > this.smartRefreshStopAfter;
	}

	doSmartRefresh() {
		++this.attemptedSmartRefreshCount;

		// if enabled, emulate a "dumb" refresh by forcing a refresh
		// after every `this.smartRefreshForceEvery` smart refresh
		// intervals
		const forceRefresh = this._smartRefreshShouldForceRefresh();

		// if enabled, stop the more frequent "smart refresh" after a
		// configurable number of non-viewable refresh attempts
		const smartRefreshStopped = this._isSmartRefreshStopped();

		const inView = this.isInView();

		if (forceRefresh) {
			// ad unit is non-viewable, but we're forcing a refresh anyways
			this.myLog(LEVEL.INFO, `Refreshing ${this.config.name} because it's configured to refresh every ${this.smartRefreshForceEvery} smart refresh interval(s). ({ inView: ${inView} })`);
			this.doRefreshInner(RefreshType.SMART_FORCED);
		} else if (inView && !smartRefreshStopped) {
			// ad unit is viewable so we'll procede with a normal smart refresh
			this.myLog(LEVEL.INFO, `Refreshing ${this.config.name} because it is in view and not stopped.`);
			this.doRefreshInner(RefreshType.SMART);
		} else {
			// ad unit is non-viewable and wasn't refreshed
			let reason = 'reasons.';
			if (smartRefreshStopped) {
				reason = `smart refresh was stopped after ${this.smartRefreshStopAfter} non-viewable refresh attempts.`;
			} else if (!inView) {
				reason = 'the ad is not in view (it is off screen).';
			}
			this.myLog(LEVEL.INFO, `Not refreshing ${this.config.name} because ${reason}`);
		}

		if (inView) {
			this.smartRefreshNonViewableAttemptCount = 0;
		} else  {
			this.smartRefreshNonViewableAttemptCount += 1;
		}
	}

	doRefresh(force) {
		this.doRefreshInner(RefreshType.TIMED);
	}

	/**
	 * Helper function for logic shared between `this.doRefresh` and
	 * `this.doSmartRefresh`
	 * @param {string} type passed to `this.refreshUnit`
	 */
	doRefreshInner(type) {
		this.adUnits.refreshUnit((doneRefreshingCB) => {
			this.doneRefreshingCB = doneRefreshingCB;
			this.refreshTargetingProviders(this, () => {
				this.refreshUnit(type);
			});
		});

	}

	refreshUnit(type) {
		let refreshDetails = {
			label: type + ' refresh',
			targetingAttribute: targetingAttributeForType(type)
		};
		this.myLog(LEVEL.INFO, `Refreshing ${this.config.name} now because of ${ refreshDetails.label }.`, this.config.slot);

		let refreshCount;
		if (refreshDetails.targetingAttribute === GamTargetingAttribute.SMART_REFRESH) {
			refreshCount = this.successfulSmartRefreshCount++;
		} else {
			refreshCount = this.currentTimedRefreshCount++;
		}
		this.myLog(LEVEL.INFO, `Setting slot targeting '${refreshDetails.targetingAttribute}' to ${refreshCount}`);
		this.config.slot.setTargeting(refreshDetails.targetingAttribute, refreshCount);

		this.impressionViewable = false;
		if (!this.smartRefresh) {
			clearInterval(this.refresh_interval);
			this.refresh_interval = undefined;
		}

		googletag.pubads().clear([this.config.slot]);
		googletag.pubads().refresh([this.config.slot]);

		if (this.doneRefreshingCB) {
			this.doneRefreshingCB();
		}
	}

	addResponseInfo(event) {
		let adElmt = document.getElementById(this.divid);
		let ri = event.slot.getResponseInformation();
		if (ri && adElmt) {
			let data = {
				responseInformation: ri,
				contentUrl: event.slot.getContentUrl(),
				escapedQemQueryId: event.slot.getEscapedQemQueryId()
			};

			let existing = adElmt.parentElement.querySelector('.ad_response_info');
			if (existing) {
				existing.innerHTML = JSON.stringify(data);
			} else {
				let dataspan = document.createElement('span');
				dataspan.style.display = 'none';
				dataspan.innerHTML = JSON.stringify(data);
				dataspan.classList.add('ad_response_info');
				this.insertBefore( adElmt, dataspan );
			}
			this.myLog(LEVEL.FLOW, `Added/updated ad response info span`);
		} else if (!adElmt) {
			this.myLog(LEVEL.WARN, `#${this.divid} was not found on the page, skipping response info span.`);
		}
	}

	finalizeSetup(cb) {
		if (this.config.slot && !this.config.displayCalled) {
			this.config.displayCalled = true;
			googletag.cmd.push(() => {
				this.waitForElement(() => {
					this.adElmt.id = this.divid;
					googletag.display(this.divid);
					this.myLog(LEVEL.FLOW, `display called`);
					if (typeof cb === 'function') {
						cb(); //eslint-disable-line callback-return
					}
				});

				googletag.pubads().addEventListener('impressionViewable', (event) => {
					if (event.slot === this.config.slot) {
						this.impressionViewable = true;
						this.trackViewability(true);
					}
				});

				googletag.pubads().addEventListener('slotRenderEnded', (event) => {
					if (event.slot === this.config.slot) {
						this.addResponseInfo(event);

						if (this.myElmt) {
							if (!this.myElmt.classList.contains('gpt_ad_unit') && !this.myElmt.classList.contains('gpt_ad_unit_loaded') && !(this.myElmt.innerHTML.indexOf('masthead') >= 0) && !(this.myElmt.innerHTML.indexOf('oop') >= 0)) {
								this.myElmt.classList.add('gpt_ad_unit');
								this.myElmt.classList.add('gpt_ad_unit_loaded');
							}
							this.myElmt.style.minHeight = 'auto';
							this.myLog(LEVEL.FLOW, `minHeight set to auto`);
						} else {
							this.myLog(LEVEL.WARN, `this.myElmt was null, couldn't set minHeight to auto`);
						}

						if (this.closebutton) {
							if (event.isEmpty) {
								this.closebutton.hideButton();
							} else {
								this.closebutton.setupButton();
							}
						}

						if (this.config.name === 'adhesion') {
							this.renderAdhesionUnit();
						}

						this.setupViewabilityTracking();
						this.setupTimedRefresh(false, event);
						this.myLog(LEVEL.FLOW, 'Display Done.');
					}
				});
			});
		}
	}

	renderAdhesionUnit() {
		let parent;
		try {
			this.myLog(LEVEL.INFO, `Using Sadlib to render Adhesion unit.`);
			let adElmt = document.getElementById(this.divid);
			if (!adElmt) throw 'Could not find adElmt.';
			let divAd = adElmt.querySelector('div'); //The actual ad div (iframe is direct child)
			if (!divAd) throw 'Could not find divAd.';
			parent = document.body.querySelector(this.selector); //The top adhesion container
			if (!parent) throw 'Could not find adElmt.parentElement.';
			let close = parent.querySelector('.adhesion_close'); //Close button
			if (!close) throw 'Could not find close button.';
			let iframe = divAd.querySelector('iframe'); //The ad iframe
			if (!iframe) throw 'Could not find iframe. Ad did not fill.';
			let iframeWidth = Number(iframe.width);
			let iframeHeight = Number(iframe.height);
			if (iframeWidth === 1 && iframeHeight === 1){
				if (this.sadlibConfig.device_type === 'desktop' || this.sadlibConfig.device_type === 'tablet'){
					iframeWidth = 728;
					iframeHeight = 90;
				} else {
					iframeWidth = 320;
					iframeHeight = 50;
				}
			}
			let closeWidth = parseInt(getComputedStyle(close).width, 10);

			let positionAdhesionElmts = () => {
				parent.style.background = ''; //Remove background if its set.
				parent.style.height = (iframeHeight + 10) + 'px'; //Add 10px of height to 'wrap' the ad.

				divAd.style.position = 'relative'; //So we can set the top.
				divAd.style.top = 10 + 'px'; //Don't leave the gap between bottom of the ad and bottom of the viewport when there's no background

				close.style.left = (((window.innerWidth / 2) + (iframeWidth / 2)) - (closeWidth + 5)) + 'px'; //Position the close button
				close.style.top = -7 + 'px'; //Close button base top position

				//If background present and the screen is wide enough for it
				if (window.innerWidth > (iframeWidth + ((closeWidth * 2) + 10))) {
					parent.style.background = 'rgba(51, 51, 51, 0.65)'; //Add background color
					divAd.style.top = 5 + 'px'; //Center ad in the 10px of wrap set above
					close.style.left = (((window.innerWidth / 2) + (iframeWidth / 2)) + 5) + 'px'; //Position the close button
				}
			};

			parent.style.display = 'block';
			positionAdhesionElmts(); //Initially position all elements

			//Adjust positions if they rotate the device (or some how change the window size)
			window.addEventListener('resize orientationchange', () => {
				positionAdhesionElmts();
			}, false);

			//Close the ad on click
			close.addEventListener('click', () => {
				parent.style.display = 'none';
				this.removeDivContents();
			}, false);

		} catch (e) {
			this.myLog(LEVEL.WARN, 'Could not setup adhesion unit correctly.', e);
			if (parent) {
				parent.style.display = 'none';
				this.removeDivContents();
			}
		}
	}

	setupViewabilityTracking() {
		try {
			let options = {
				root: null,
				rootMargin: '0px',
				threshold: [0.5, 1] //These should match closely with what gpt is using.
			};
			this.viewabilityObserver = new IntersectionObserver((entries) => {
				this.viewability = entries[0];
				this.trackViewability(false);
			}, options);
			this.viewabilityObserver.observe(this.myElmt.querySelector('iframe'));
			this.myLog(LEVEL.FLOW, `Started viewability observer for ${this.config.name}.`);
		} catch (ignored) {
			this.myLog(LEVEL.WARN, `Count not start viewability observer for ${this.config.name}.`);
		}
	}

	buildElement(template) {
		let element = document.createElement('div');
		if (this.config.sizes[0] && Array.isArray(this.config.sizes[0]) && !isNaN(this.config.sizes[0][1]) && this.config.collapseBeforeFetch !== true) {
			//set the minHeight of the div to the first height in the sizes config array
			element.style.minHeight = this.config.sizes[0][1]+'px';
		}
		element.innerHTML = template;
		if (this.config.class) {
			element.classList.add(this.config.class);
		}
		return element;
	}

	/**
	 * @param {Node} insertTarget
	 * @param {Node} element
	 * @param {string} [selector]
	 * @returns {Node}
	 */
	getAdElement(insertTarget, element, selector) {
		if (!selector) {
			this.myLog(LEVEL.FLOW, `no ad \`selector\` specified falling back to \`element\``);
			return element;
		}

		const adSelectorElement = insertTarget.parentElement.querySelector(selector); // Narrow search to element's parent or grandparent
		if (!adSelectorElement) {
			this.myLog(LEVEL.WARN, `could not find element for ad selector ${selector}, falling back to \`element\``);
			return element;
		}

		if (!element.contains(adSelectorElement)) {
			// if `adSelectorElement` is not a child element of `element` stuff may break due to the initializaiton order of other code/modules (see STRT-8586)
			this.myLog(LEVEL.WARN, `ad \`selector\` ${selector} SHOULD be a child element of \`element\`, falling back to using \`element\` instead of \`selector\``);
			return element;
		}

		this.myLog(LEVEL.FLOW, `using ad selector ${selector}`);
		return adSelectorElement;
	}

	addAttributes(adElmt, element, id, name, closeButton) {
		adElmt.setAttribute('data-gpt-unit_name', name);
		if (closeButton) {
			this.closebutton = new AdUnitClose(this.sadlibConfig, element, adElmt, this.config.name, this.config.wrapperMarginTop);
		}
	}

	render( insertTarget, insertMethod ) {
		try {
			if (!this.myElmt) {
				this.myElmt = this.buildElement(this.config.template || '');

				insertMethod.call(this, insertTarget, this.myElmt);

				this.adElmt = this.getAdElement(insertTarget, this.myElmt, this.config.adSelector);
				this.addAttributes(this.adElmt, this.myElmt, this.divid, this.config.name, this.config.closebutton);
				this.myLog(LEVEL.FLOW, `added ${this.config.name} to page`);
			}
		} catch (e) {
			this.myLog(LEVEL.ERROR, 'error', `Could not add ${this.config.name} to page.`, e);
		}
	}

	insertChild( target, element ){
		target.appendChild( element );
	}

	insertBefore( target, element ){
		target.parentElement.insertBefore( element, target );
	}

	insertAfter( target, element ){
		target.parentElement.insertBefore( element, target.nextElementSibling );
	}

	replaceElement( target, element ){
		target.parentElement.replaceChild( element, target );
	}

	isInView(){
		this.myLog(LEVEL.INFO, `Checking if ${this.config.name} is in view`);
		if (isDocumentInFocus()) {
			this.myLog(LEVEL.INFO, `document is in focus for ${this.config.name}`);
			if (this.viewability.intersectionRatio !== undefined && this.viewability.intersectionRatio >= 0.5) {
				this.myLog(LEVEL.INFO, `intersectionRatio for ${this.config.name} is ${this.viewability.intersectionRatio}`);
				return true;
			}
			this.myLog(LEVEL.INFO, `intersectionRatio for ${this.config.name} either undefined or less than 0.5`);
		} else {
			this.myLog(LEVEL.INFO, `document is not in focus for ${this.config.name}`);
		}
		return false;
	}

	trackViewability(isGptEvent){
		if ( !this.myElmt ){
			this.myLog(LEVEL.WARN, `Could not track viewability for ${this.config.name} because element is missing` );
			return;
		}
		let ratio = (this.viewability.intersectionRatio !== undefined) ? Math.round(this.viewability.intersectionRatio * 100) : 0;
		this.myLog(LEVEL.INFO, `Recording viewability for ${this.config.name}: ${ratio} Is gpt event: ${isGptEvent}`);
		window.googletag.cmd.push(() => {
			try {
				this.config.slot.setTargeting('viewability', ratio);
				this.config.slot.setTargeting('gptevent', isGptEvent);
			} catch (e) {
				this.myLog(LEVEL.ERROR, 'error', `Unable to track viewability for ${this.config.name}`, e);
			}
		});
	}

	isElementInViewport(el) {
		let inViewport = false;
		if ( el ){
			let avgHeight = this.getAverageHeight();
			if (avgHeight > 0) {
				avgHeight = Math.round(avgHeight / 2); //Use half of the height for the calculations
			}
			let rect = el.getBoundingClientRect();
			inViewport = (rect.bottom - avgHeight) > 0 &&
				rect.right > 0 &&
				rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
				(rect.top + avgHeight) < (window.innerHeight || document.documentElement.clientHeight);
		}
		this.myLog(LEVEL.DEBUG, `Element is ${(!inViewport) ? 'not ' : ''}in viewport.`, el);
		return inViewport;
	}

	/**
	 * Calculates the average height of the sizes array, ignoring any 'fluid' sizes.
	 * This also rounds the result to a whole number.
	 */
	getAverageHeight() {
		if (typeof this.config.averageHeight === 'undefined') {
			let sum = 0;
			let avg = 0;
			let lengthToUse = 0;
			let s = this.config.sizes;
			if (s && s.length) {
				sum = s.reduce((acc, cur) => {
					if (cur[1] && typeof cur[1] === 'number') {
						acc += cur[1];
						lengthToUse++;
					}
					return acc;
				}, 0);
				avg = sum / lengthToUse;
			}
			this.config.averageHeight = Math.round(avg);
		}
		return this.config.averageHeight;
	}

	getParents(elmt) {
		let els = [];
		while (elmt) {
			els.unshift(elmt);
			elmt = elmt.parentNode;
		}
		return els;
	}

	removeDivContents() {
		let adElmt = document.getElementById(this.divid);
		if (adElmt) {
			let p = adElmt.parentElement;
			if (p) {
				p.removeChild(adElmt);
			}
		}
		try {
			this.viewabilityObserver.disconnect();
		} catch (ignored) {}
		this.viewabilityObserver = null;
	}
}
