import logger, { LEVEL } from '../components/logger';

let myLog;
let provider;
let delay;

export default function initialize(name, cfg) {
	myLog = logger('TargetingProvider:' + name, cfg.verbosity);
	myLog(LEVEL.FLOW, 'Initializing');
	provider = name;
	delay = (cfg.testMode && cfg.testModeParams.delayFinished) ? cfg.testModeParams.delayFinished : false;
	return {
		attachUnit,
		run
	};
}

function attachUnit(unit) {
	myLog(LEVEL.FLOW, 'attachUnit called with unit:', unit);
}

function run(req) {
	myLog(LEVEL.FLOW, 'run called with request:', req);
	if (delay) {
		myLog(LEVEL.FLOW, `delaying handleProviderFinished by ${delay}ms.`);
		setTimeout(() => {
			myLog(LEVEL.FLOW, 'calling handleProviderFinished');
			req.handleProviderFinished(provider);
		}, delay);
	} else {
		myLog(LEVEL.FLOW, 'calling handleProviderFinished');
		req.handleProviderFinished(provider);
	}
}