import logger, { LEVEL } from './logger';

/**
 * @typedef {object} ProviderLogEntry
 * @property {*} timedOutAt
 * @property {*} finishedAt
 */

export default class TargetingProviderRequest {
	timeoutInstance;
	allFinished = false;
	finishedCallback;

	/** @type {Object.<string, ProviderLogEntry>} */
	providerLogs = {};

	/** @type {import("./AdUnit").default[]} */
	units = [];

	//Run this callback when this request has finished (all providers have finished or the timeout has happened)
	constructor(config, cb, u, ms) {
		this.config = config;
		this.myLog = logger('TargetingProviderRequest', this.config.verbosity);
		this.finishedCallback = cb;
		this.units = u || [];
		this.ms = ms;
	}

	startTimer() {
		this.myLog(LEVEL.CONFIG, `Targeting Providers will timeout in ${this.ms} ms`);
		this.timeoutInstance = setTimeout(() => {
			this.handleTimeout();
		}, this.ms);
	}

	addProvider(name) {
		this.providerLogs[name] = {
			timedOutAt: undefined,
			finishedAt: undefined
		};
	}

	handleTimeout() {
		this.myLog(LEVEL.ERROR, 'Targeting Providers Timeout');
		this.eachProvider((name, logs) => {
			if (!logs.timedOutAt && !logs.finishedAt) {
				logs.timedOutAt = Date.now();
				this.myLog(LEVEL.ERROR, name + ' timed out');
			}
		});
		this.handleAllFinished();
	}
	handleAllFinished() {
		this.myLog(LEVEL.INFO, 'Targeting Providers Finished');
		clearTimeout(this.timeoutInstance);
		this.allFinished = true;
		if (typeof this.finishedCallback === 'function') {
			this.finishedCallback();
			this.finishedCallback = null;
		}
	}
	handleProviderFinished(name) {
		if (!this.allFinished) {
			this.providerLogs[name].finishedAt = Date.now();
			this.myLog(LEVEL.DEBUG, name + ' finished');
			this.allFinished = true;
			this.eachProvider((name, logs) => {
				if (!logs.finishedAt) {
					this.allFinished = false;
				}
			});
			if (this.allFinished) {
				this.handleAllFinished();
			}
		} else {
			this.myLog(LEVEL.WARN, `${name} finished too late, and is not included in this ad request.`);
		}
	}

	getProviders() {
		return Object.keys(this.providerLogs);
	}

	getUnits() {
		return this.units;
	}

	/**
	 * @param {import("./AdUnit").default} unit
	 */
	addUnit(unit) {
		this.units.push(unit);
	}

	/**
	 * @param {(string, ProviderLogEntry) => any} cb
	 */
	eachProvider(cb) {
		// `this.providerLogs` doesn't implement `[Symbol.iterator]`. We
		// can't use `for ... of ...` here because of this.
		for (let name in this.providerLogs) {
			if (Object.prototype.hasOwnProperty.call(this.providerLogs, name)) {
				cb(name, this.providerLogs[name]); //eslint-disable-line callback-return
			}
		}
	}
}
