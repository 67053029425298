import logger, { LEVEL } from '../components/logger';
window.rubicontag = window.rubicontag || {};
window.rubicontag.cmd = window.rubicontag.cmd || [];


let myLog;
let providerConfig;
let adunitConfig;

export default function initialize(name, cfg) {
	myLog = logger('TargetingProvider:' + name, cfg.verbosity);
	myLog(LEVEL.FLOW, 'Initializing');
	providerConfig = cfg.targetingProviders[name];
	providerConfig.name = name;
	//Inject rubicon fastlane js
	if (window && window.rubicontag && typeof window.rubicontag.run === 'function') {
		myLog(LEVEL.FLOW, 'Rubicon fastlane library already loaded');
	} else {
		let rpfl_script = document.createElement('script');
		rpfl_script.type = 'text/javascript';
		rpfl_script.id = 'rubicon_script_loaded';
		rpfl_script.async = true;
		rpfl_script.src = providerConfig.script_url;
		rpfl_script.onload = function () {
			myLog(LEVEL.FLOW, 'Rubicon Fastlane library finished parsing and executing');
		};
		let node = document.getElementsByTagName('script')[0];
		node.parentNode.insertBefore(rpfl_script, node);
	}

	return {
		attachUnit,
		run
	};
}

function attachUnit(unit) {
	if (typeof rubicontag !== 'undefined' && rubicontag.cmd !== undefined) {
		rubicontag.cmd.push(() => {
			let position = getUnitPosition(unit);
			let div_id = (unit.ref && unit.ref.divid) ? unit.ref.divid : 'div-gpt-ad-' + unit.name;
			if (!rubicontag.getSlot(div_id)) {
				rubicontag.defineSlot(unit.path, unit.sizes, div_id).setPosition(position);
			}
		});
	}
}

function run(request) {
	adunitConfig = request.getUnits();
	if (typeof rubicontag !== 'undefined' && rubicontag.cmd !== undefined) {
		rubicontag.cmd.push(() => {
			//Set domain to window's host.
			let uri = window.document.location.href;
			let parser = window.document.createElement('a');
			parser.href = uri;
			rubicontag.setFPI('domain', parser.hostname);
			rubicontag.run(() => {
				getTargeting();
			});
		});
		request.handleProviderFinished(providerConfig.name);
	}
}

function getUnitPosition(unit) {
	return (unit.path.indexOf('btf') > -1) ? 'btf' : 'atf';
}

function getTargeting() {
	let rpfl_slots = rubicontag.getAllSlots();
	rpfl_slots.forEach((rpfl_slot) => {
		let rpfl_slot_targeting_kv_pairs = rpfl_slot.getAdServerTargeting();
		rpfl_slot_targeting_kv_pairs.forEach((targeting_attributes) => {
			let adunit = adunitConfig.filter((gpt_unit) => gpt_unit.config.path === rpfl_slot.getSlotName());
			let gpt_unit = [];
			if (adunit && adunit.length === 1 && targeting_attributes.values) {
				gpt_unit = adunit[0];
				googletag.cmd.push(() => {
					gpt_unit.config.slot.setTargeting(targeting_attributes.key, targeting_attributes.values);
				});
			}
		});
	});
}
