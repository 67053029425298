/*
 * This is the sadlib library.
 * To enable console logging and print help information, run this in your console:
 * localStorage.debug = "sadlib:*";
 */
import Gpt from './lib/components/Gpt';
import AdUnits from './lib/components/AdUnits';
import DetectPii from './lib/components/DetectPii';
import cfg from 'CLIENT/config.json';
import TargetingProviders from './lib/components/TargetingProviders';
import ClientCode from 'CLIENT/customjs.js';
import logger, { LEVEL } from './lib/components/logger';

window.TN8 = window.TN8 || {};
window.TN8.q = window.TN8.q || [];
window.TN8.Auction = window.TN8.Auction || {};

window.Sadlib_Config = window.Sadlib_Config || {};
window.Sadlib = {}; //Used below for global methods

let verbosity = (localStorage && localStorage.debugLevel && localStorage.debugLevel.length) ? parseInt(localStorage.debugLevel, 10) : 2;
let debugFrame = !!((localStorage && localStorage.debugFrame && localStorage.debugFrame === 'on'));
let testMode = !!((localStorage && localStorage.sadlibTestMode && localStorage.sadlibTestMode === '1'));
let testModeParams;
const piiTrackingExclusions = [
	'/search/email'
];
let myLog = logger('main', verbosity);
myLog(LEVEL.CONFIG, 'Sadlib Logging Enabled');
myLog(LEVEL.CONFIG, `
Sadlib Debugging Information:
Sadlib supports several debugging options. Most of them are set via localStorage, but some are via the browser url.

Disable Ads:
You can disable ads completly by passing the following url param: disable_ads=1
You can also disable ads by setting localStorage.disable_ads=1;
This setting will persist via localStorage across page loads. This is mainly used for automated testing purposes.
To re-enable ads, put disable_ads=0 in the url and reload the page, or set localStorage.disable_ads=0;

Logging:
Sadlib uses npm debug (https://www.npmjs.com/package/debug), and therefore supports all of its features.
To enable console logging, set localStorage.debug = "sadlib:*";

Logging Verbosity:
Sadlib has 6 levels of logging verbosity. 2 is the default.
0 === least verbose (exceptions)
1 === (warnings)
2 === (configuration)
3 === (standard information)
4 === (runtime flow)
5 === most verbose (debugging)
You set the verbosity level to 5, for example, by setting localStorage.debugLevel = "5";
If the level of verbosity you choose is too verbose, but you don't want to go to a lower level, you can
tell the debug module to hide specific classes. For example: localStorage.debug = "sadlib:*,-sadlib:ClientCode";

Debug Frame:
Sadlib can put a red frame around each ad unit for development purposes.
To enable this, set localStorage.debugFrame = "on";

Test Mode:
Sadlib supports a test mode that you can use to force certain conditions for testing or development.
To enable it, set localStorage.sadlibTestMode = "1";
After turning on test mode, you must also set which classes you want to enter test mode, and any params they require.
See each class for specific information regarding options for this.
To turn on test mode for targeting providers, for example, set localStorage.sadlibTestModeParams = '{"class":"TargetingProviders"}';
To turn on test mode for targeting providers and prebid_bidders, for example, set localStorage.sadlibTestModeParams = '{"class":"TargetingProviders,prebid_bidders"}';
`);
myLog(LEVEL.CONFIG, `Sadlib logging verbosity set to ${verbosity}. (To set: localStorage.debugLevel = "2";)`);
myLog(LEVEL.CONFIG, `Sadlib debug frame is ${(debugFrame) ? 'on' : 'off'}. (To set: localStorage.debugFrame = "on";)`);
if (testMode) {
	myLog(LEVEL.WARN, '--- SADLIB TEST MODE ENABLED --- (To disable it: localStorage.sadlibTestMode = "0";)');
	try {
		testModeParams = JSON.parse(localStorage.sadlibTestModeParams);
		myLog(LEVEL.CONFIG, 'sadlibTestModeParams:', testModeParams);
	} catch (ignored) {
		myLog(LEVEL.WARN, '--- SADLIB TEST MODE PARAMS COULD NOT BE PARSED ---');
		myLog(LEVEL.WARN, 'localStorage.sadlibTestModeParams should be a JSON parsable object. Exiting and not rendering ads.');
		window.Sadlib_Config.initialized = true;
	}
}

//Disable ads functionality
if (window.location.href.match(/disable_ads=0/)) {
	if (localStorage) {
		localStorage.disable_ads = '0';
	}
} else if (window.location.href.match(/disable_ads=1/) || (localStorage && localStorage.disable_ads === '1')) {
	if (localStorage) {
		localStorage.disable_ads = '1';
	}
	myLog(LEVEL.WARN, '--- Ads are disabled, Exiting --- (To re-enable, put "disable_ads=0" in the url and refresh the page.)');

	//Set flag so we do not initialize:
	window.Sadlib_Config.initialized = true;
}

if (!window.Sadlib_Config.initialized) {
	window.Sadlib_Config.initialized = true;

	let gpt;
	let config = JSON.parse(JSON.stringify(cfg)); //Make the config object mutable
	config.verbosity = verbosity;
	config.debugFrame = debugFrame;
	config.testMode = testMode;
	config.testModeParams = testModeParams;
	let adUnits = null;

	// eslint-disable-next-line no-inner-declarations
	function initializeSadlib() {
		//Setup logger and version number
		const sadlib_version = PACKAGE.version;
		myLog(LEVEL.INFO, 'sadlib version:', sadlib_version);

		//Initialize client js
		let client = new ClientCode(config);

		//Let the client's customjs mutate the config
		if (typeof client.mutateConfig === 'function') {
			config = client.mutateConfig(config);
			myLog(LEVEL.FLOW, 'Mutated Configuration');
		}
		myLog(LEVEL.CONFIG, 'Final Configuration:', config);

		//Call custom code with config for third party scripts
		if (typeof client.applyCustomThirdPartyCode === 'function') {
			client.applyCustomThirdPartyCode(config);
		}

		//Sadlib load type
		if (config.load_type) {
			myLog(LEVEL.INFO, 'Sadlib Load Type:', config.load_type);
		} else {
			myLog(LEVEL.INFO, 'Sadlib Load Type:', 'unknown');
		}

		//Sync script loading
		if (!config.loadScriptsAsync) {
			myLog(LEVEL.INFO, 'Scripts Load Type:', 'sync');
		} else {
			myLog(LEVEL.INFO, 'Scripts Load Type:', 'async');
		}

		//Exit early if we have no configured ad units.
		if (config && config.units && !config.units.length) {
			//@TODO We should be able to re-enable this but something with smartwrapper breaks.
			//This has somehting to do with the other version on the page. Once we remove that,
			//this should be fine.
			//myLog(LEVEL.WARN, 'No ad units configured. Exiting early.');
			//return;
		}

		//Setup gpt.js
		myLog(LEVEL.FLOW, 'Initializing gpt.js');
		window.googletag = window.googletag || { cmd: [] };
		gpt = new Gpt(config);

		//Setup targeting providers
		let targetingProviders = new TargetingProviders(config, () => {
			myLog(LEVEL.FLOW, 'Targeting Providers Finished');
			finalizeSetup();
		});

		//Detect pii
		let detectPii = new DetectPii(config);
		window.SynDetectPii = detectPii;
		if (detectPii.hasPii()) {
			myLog(LEVEL.FLOW, 'pii detected');
			if (config.trackPii) {
				const currentPath = (window.location && window.location.pathname) ? window.location.pathname : '';
				const excludePathIndex = piiTrackingExclusions.findIndex((excludePath) => currentPath.indexOf(excludePath) > -1);
				if (excludePathIndex === -1) {
					myLog(LEVEL.FLOW, 'tracking pii detection');
					detectPii.track();
				}
			}
			if (config.blockOnPii) {
				myLog(LEVEL.WARN, 'blocking ads because of pii');
				return;
			}
		}

		//Call client's customjs finishInit method and let them kill ads if they want to
		if (typeof client.finishInit === 'function' && client.finishInit() === false) {
			myLog(LEVEL.WARN, 'client js killed script on purpose, exiting early.');
			return;
		}

		//If BABU (Block Ads By User) is set, just exit here.
		if (typeof window.Sadlib_Config.babu !== 'undefined' && window.Sadlib_Config.babu === true) {
			myLog(LEVEL.WARN, 'BABU is set, exiting early.');
			return;
		}

		//Setup ad units and attach them to the targeting providers
		myLog(LEVEL.FLOW, 'instantiating each ad unit');
		adUnits = new AdUnits(config);

		myLog(LEVEL.FLOW, 'setting up each ad unit');
		adUnits.setup(targetingProviders);
		myLog(LEVEL.FLOW, 'adunits initialized');

		//Setup global targeting
		myLog(LEVEL.FLOW, 'setting page level targeting attributes');
		targetingProviders.setPageLevelTargetingAttributes(client);
	}

	//This is called when the targeting providers finish above
	// eslint-disable-next-line no-inner-declarations
	function finalizeSetup() {
		//Call enable services (run the auction)
		myLog(LEVEL.FLOW, 'queuing gpt enable services');
		gpt.finalizeSetup();

		//Call display on each ad unit
		myLog(LEVEL.FLOW, 'queuing ad units display');
		adUnits.finalizeSetup();
		myLog(LEVEL.FLOW, 'main script finished');
	}

	// Use this method to destroy all references to the existing GPT slots
	// And re-initialize sadlib (SPA applications)
	window.Sadlib.resetAds = function () {
		myLog(LEVEL.ERROR, '--- RESETTING ADS ---');
		if (googletag && googletag.destroySlots) {
			googletag.destroySlots(); //Destroy all slots
			if (adUnits) {
				adUnits.removeStyleBlock();
				adUnits.clearDivContents();
			}
			config = JSON.parse(JSON.stringify(cfg)); //Reset the configuration
			config.verbosity = verbosity; //Reset the verbosity
			config.debugFrame = debugFrame; //Reset the debugFrame
			adUnits = null; //Delete the adUnits object
			gpt = null; //Delete the gpt object
			initializeSadlib(); //Initialize all over again
		}
	};

	if (typeof IntersectionObserver !== 'function') {
		let s = document.createElement('script');
		s.async = true;
		s.type = 'text/javascript';
		//features=IntersectionObserver (load this only)
		//rum=0 (disable real user monitoring)
		s.src = '//cdn.polyfill.io/v2/polyfill.min.js?features=IntersectionObserver&rum=0';
		s.onload = initializeSadlib; //According to their website, this is a fine way to init our stuff after the polyfill loads
		let node = document.getElementsByTagName('script')[0];
		node.parentNode.insertBefore(s, node);
	} else {
		initializeSadlib();
	}
} //else - second instance of sadlib included on page - just noop
