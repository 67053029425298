import logger, { LEVEL } from './logger';
import { getFirstPartyUserId } from './firstPartyUserId';

export default class Gpt {
	constructor(config) {
		this.config = config;
		this.myLog = logger('Gpt', this.config.verbosity);
		this.addGptJs();
	}
	addGptJs() {
		if (!window.googletag.destroySlots) {
			let gads = document.createElement('script');
			if (this.config.loadScriptsAsync) {
				gads.async = true;
			} else {
				gads.async = false;
			}
			gads.type = 'text/javascript';
			let useSSL = 'https:' === document.location.protocol;
			gads.src = (useSSL ? 'https:' : 'http:') + '//securepubads.g.doubleclick.net/tag/js/gpt.js';
			gads.onload = () => {
				this.myLog(LEVEL.FLOW, 'gpt.js loaded');
			};
			let node = document.getElementsByTagName('script')[0];
			node.parentNode.insertBefore(gads, node);
		}
	}
	finalizeSetup() {
		googletag.cmd.push(() => {
			let pubads = googletag.pubads();

			// CCPA Compliance: Detect if the user has opted-out. If so, send the correct signal to GPT.
			if (this.config.ccpaCompliance && window.__uspapi) {
				window.__uspapi('getUSPData', 1, (uspData, success) =>  {
					if (success && uspData && uspData.uspString && uspData.uspString.charAt(2) === 'Y') {
						pubads.setPrivacySettings({ restrictDataProcessing: true });
						this.myLog(LEVEL.CONFIG, `CCPA restricted data processing enabled`);
					} else {
						this._setGptPpid(pubads);
					}
				});
			} else {
				this._setGptPpid(pubads);
			}

			if (this.config.enableSingleRequest) {
				this.myLog(LEVEL.CONFIG, `Enable Single Request Mode Set`);
				pubads.enableSingleRequest();
			}
			if (this.config.enableLazyLoad) {
				this.myLog(LEVEL.CONFIG, `Enable Lazy Load Set`, this.config.enableLazyLoad );
				if ( typeof this.config.enableLazyLoad === 'object' ){
					pubads.enableLazyLoad( this.config.enableLazyLoad );
				} else {
					pubads.enableLazyLoad();
				}
			}
			googletag.companionAds().setRefreshUnfilledSlots(true);
			pubads.set('page_url', document.URL);
			googletag.enableServices();
			this.myLog(LEVEL.FLOW, 'gpt enable services occured');
		});
	}

	_setGptPpid(pubads) {
		const firstPartyUserId = getFirstPartyUserId();
		this.myLog(LEVEL.FLOW, `setting GPT PPID to first party user id: ${firstPartyUserId}`);
		pubads.setPublisherProvidedId(firstPartyUserId);
	}
}
