
import logger, { LEVEL } from './logger';
let myLog = logger('sadlib:focusUtils');

/**
 * Tries to check if we're in an in-focus browser window or tab. Falls
 * back to returning `true` for cases where we're uncertain about the
 * focus state.
 *
 * NOTE: If you've opened Chrome DevTools and/or clicked on the DevTools
 * panel then the document you're inspecting will be out of focus until
 * you've changed the browser's focus back to it (which can be done by
 * clicking on it).
 *
 * @returns {boolean} `true` if the window or tab might be in focus or
 *          `false` if the window or tab is not focused
 */
export function isDocumentInFocus() {
	try {
		// We don't have a good way to detect browser focus unless the
		// document is in the top level window. Since any of ad units in
		// the document may be viewable we'll fail open and allow smart
		// refresh to run.
		if (!isDocumentTopLevel()) {
			return true;
		}
		return document.hasFocus();
	} catch (err) {
		myLog(LEVEL.ERROR, `Error while checking if document is in focus: ${err}`);
		// fail open to allow the smart refresh mechanism to run
		return true;
	}
}

/**
 * Tries to figure out if `document` is the top level document (e.g.,
 * not inside an iframe). Returns `false` if we're not 100% sure.
 *
 * @returns {boolean}
 */
function isDocumentTopLevel() {
	try {
		const topLevelWindow = window.top;
		if (topLevelWindow === null) {
			return false;
		}
		return window.top === window.self;
	} catch (err) {
		myLog(LEVEL.DEBUG, `Error while checking if current document is the top level document. Is there an iframe related cross site origin problem?: ${err}`);
		return false;
	}
}
