import TrackingModule from './TrackingModule';
import queryString from 'query-string';
import once from '../components/myArrive';
import logger, { LEVEL } from '../components/logger';

export default class TrackingPixel extends TrackingModule {

	constructor( config ){
		super( config );
		this.config = config;
		this.myLog = logger('TrackingPixel', this.config.verbosity);
	}

	track( options ) {
		options = options || {};
		this.myLog(LEVEL.INFO, `Tracking event:`, options );
		return this.createTrackingImage( options );
	}

	createTrackingImage( options ) {

		let args = options.args || {};
		args.page = this.getPageContext();
		args.ts = ( new Date()).getTime();

		let img = document.createElement( 'img' );
		let path = this.config.imagePath || 'images/track/track.gif';
		img.src = this.config.siteRoot + path + '?' + queryString.stringify( args );
		img.width = 1;
		img.height = 1;

		once( document.querySelector( 'body' ), () => document.body.appendChild(img));
	}

	getPageContext() {
		return document.title || 'sadlib';
	}

}
