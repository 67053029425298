import adunit from './AdUnit';
import logger, { LEVEL } from './logger';
let styleBlock;


export default class AdUnits {
	//Ad unit types must be member variables so we can instantiate them correctly below.
	AdUnit = adunit;
	totalUnits = 0;
	unitRefreshQueue = [];
	unitIsRefreshing = false;

	constructor(config) {
		this.config = config;
		this.myLog = logger('AdUnits', this.config.verbosity);
		this.sra = (this.config.enableSingleRequest === true);
		this.displayCalled = false;

		this.units = [...this.config.units];

		this.myLog(LEVEL.CONFIG, 'Configured Units', this.units);

		//Instantiate each unit
		this.units.forEach((unit) => {
			unit.ref = new this.AdUnit(unit, this.config, this);
		});
	}

	removeStyleBlock() {
		styleBlock.parentElement.removeChild(styleBlock);
		styleBlock = null;
	}

	clearDivContents() {
		this.units.forEach((unit) => {
			unit.ref.removeDivContents();
		});
	}

	setup(targetingProviders) {
		this.units.forEach((unit) => {
			//If any units have styles, add the style block to the head.
			//This lets us use a single block for all units.
			if (this.config.debugFrame) {
				unit.styles = unit.styles || '';
				unit.styles += ' .sadlib_ad_debug { border: 1px solid red; }';
				unit.template = unit.template || '';
				unit.template = "<div class='sadlib_ad_debug'>" + unit.template + '</div>';
			}
			if (unit.styles) {
				if (!styleBlock) {
					let head = document.head || document.getElementsByTagName('head')[0];
					styleBlock = document.createElement('style');
					styleBlock.id = 'sadlibStyleBlock';
					styleBlock.type = 'text/css';
					head.appendChild(styleBlock);
					this.myLog(LEVEL.FLOW, 'Added style block to head.');
				}
				unit.styleBlock = styleBlock;
			}
			unit.ref.setup(targetingProviders);
		});
	}

	finalizeSetup() {
		if (this.sra && !this.displayCalled) {
			this.myLog(LEVEL.FLOW, `queuing units`);
			this.displayCalled = true;
			this.units.forEach((unit) => {
				this.totalUnits++;
				unit.ref.finalizeSetup(() => {
					this.adDisplayed();
				});
			});
		}
	}

	adDisplayed() {
		this.totalUnits--;
		if (this.totalUnits === 0) {
			this.totalUnits--;
			this.myLog(LEVEL.INFO, 'All ad units in this fetch have been displayed.');
		}
	}

	refreshUnit(cb) {
		this.unitRefreshQueue.push(cb);
		this.myLog(LEVEL.INFO, 'Adding unit to refresh queue.');
		this.processRefreshQueue();
	}

	processRefreshQueue() {
		if (this.unitRefreshQueue.length && !this.unitIsRefreshing) {
			this.unitIsRefreshing = true;
			let doRefresh = this.unitRefreshQueue.shift();
			this.myLog(LEVEL.INFO, 'Refreshing unit now.');
			doRefresh(() => {
				this.unitIsRefreshing = false;
				this.myLog(LEVEL.INFO, 'Done refreshing unit, checking queue for more.');
				this.processRefreshQueue();
			});
		}
	}
}
