import Tracker from './Tracker';
import logger, { LEVEL } from './logger';

const PII_REGEX = /.+@[^&\s]+\.[^&\s]+/;

export default class DetectPii {

	constructor(config) {
		this.config = config;
		this.myLog = logger('DetectPii', this.config.verbosity);
		this.piiUrl = 'unknown';
		this.tracker = new Tracker(config);
	}

	hasPii() {
		function decodeRecursively(str) {
			let i = 0, decoded = str;
			do {
				str = decoded;
				decoded = decodeURIComponent(str);
				i++;
			} while (i < 10 && decoded !== str);
			return decoded;
		}
		let hasPii = PII_REGEX.test(decodeRecursively(window.location.href));
		let referrerHasPii = PII_REGEX.test(decodeRecursively(document.referrer));

		if (hasPii) {
			this.piiUrl = window.location.href;
		} else if (referrerHasPii) {
			this.piiUrl = document.referrer;
		}

		this.myLog(LEVEL.FLOW, 'hasPii: ' + hasPii + ' referrerHasPii: ' + referrerHasPii + ' URL: ' + this.piiUrl);
		return hasPii || referrerHasPii;
	}

	track() {
		this.tracker.track({
			args: {
				track: 'detected-pii',
				descr: 'Detected PII in URL',
				url: window.location.href,
				referrer: document.referrer,
				jsPageUri: this.piiUrl,
				jsSiteId: this.config.siteId,
				jsPortalRoot: this.config.siteRoot,
				bePageUri: 'sadlib',
				bePageUrl: document.referrer,
				beHost: window.location.hostname
			}
		});
	}

}
