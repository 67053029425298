import logger, { LEVEL } from './logger';
let myLog = logger('myArrive', 0);
//This is a simple method that executes a callback when a given element exists.
//It only runs the callback a single time.
let callbacks = {};

let dispatchCallbacks = () => {
	let callbacksPending = false;
	// `callbacks` doesn't implement `[Symbol.iterator]`. We can't use
	// `for ... of ...` here because of this.
	for (let selector in callbacks) {
		if (Object.prototype.hasOwnProperty.call(callbacks, selector)) {
			let node = document.querySelector(selector);
			if (node) {
				let cbs = callbacks[selector];
				delete callbacks[selector];
				for (let i = 0;i < cbs.length; i++) {
					try {
						cbs[i].call(node);
					} catch (e) {
						myLog(LEVEL.ERROR, 'error', 'Callback [' + selector + '] error: ' + e);
					}
				}
			} else {
				callbacksPending = true;
			}
		}
	}
	if (document.readyState === 'complete') {
		if (ival) {
			clearInterval(ival);
			ival = null;
		}
		if (callbacksPending) {
			ival = setTimeout(dispatchCallbacks, 500);
		}
	}
};

let ival = null;

export default function (selector, cb) {
	callbacks[selector] = callbacks[selector] || [];
	callbacks[selector].push(cb);
	if (!ival) {
		ival = setInterval(dispatchCallbacks, 40);
	}
}
