import logger, { LEVEL } from '../components/logger';

let myLog;
let provider;
let providerConfig;

export default function initialize(name, cfg) {
	myLog = logger('TargetingProvider:' + name, cfg.verbosity);
	myLog(LEVEL.FLOW, 'Initializing');
	providerConfig = cfg.targetingProviders[name];
	providerConfig.name = name;
	provider = name;
	let script_url = providerConfig.script_url;

	// FIXME: figure out if this is dead code
	if ((/iPad|iPhone|iPod/i.test(navigator.platform) && !window.MSStream) ||(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
		let nextroll = document.createElement('script');
		nextroll.type = 'text/javascript';
		nextroll.id = 'nextroll_loaded';
		nextroll.async = true;
		nextroll.src = script_url;
		document.getElementsByTagName('head')[0].appendChild(nextroll);

		nextroll.onload = function() {
			myLog(LEVEL.FLOW, 'Nextroll XID (IDFA) loaded');

			let cb = (data) => {
				const xid = data;
				if ((xid !== null) && (localStorage.getItem('nextrollXid') === null || localStorage.getItem('nextrollXid') !== xid)) {
					localStorage.setItem('nextrollXid', xid);
					let xhr = new XMLHttpRequest();
					const params = 'srv=xs&pid=nextroll&xid=' + xid;
					xhr.open('POST', 'https://uat-net.technoratimedia.com/services', true);
					xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
					xhr.withCredentials = true;
					xhr.send(params);
				}
			};
			// eslint-disable-next-line new-cap
			window.RetrieveAdID(cb, cb, { PID: providerConfig.partner_id });

			try {
				// This is temporary code to manually retrieve NextRoll's XID until our prebid adapter side changes are done and merged upstream.
				const key = 'AdID:' + (providerConfig.partner_id || '');
				const dataString = window.localStorage.getItem('dca0.com') || '{}';
				const data = JSON.parse(dataString);
				// FIXME: STRT-8474 is this defined anywhere or is it guarenteed to fail?
				// eslint-disable-next-line no-undef
				const xid = deepAccess(data, `${key}.value`);
				if (xid) {
					let xhr = new XMLHttpRequest();
					const params = 'srv=xs&pid=nextroll&xid=' + xid;
					xhr.open('POST', 'https://uat-net.technoratimedia.com/services', true);
					xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
					xhr.withCredentials = true;
					xhr.send(params);
				}
			} catch (e) {
				myLog(LEVEL.ERROR, 'Error retrieving NextRoll XID');
			}
		};
	}
	return {
		run
	};
}

function run(request) {
	request.handleProviderFinished(provider);
}
