import logger, { LEVEL } from './logger';

export default class AdUnitClose {
	baseClass = 'sadlib_ad_unit_masthead';
	closeButtonLabel = 'Close Ad';
	showButtonLabel = 'Show Ad';

	constructor(config, adUnitDiv, adDiv, name, wrapperMarginTop) {
		this.name = name;
		this.config = config;
		this.adUnitDiv = adUnitDiv;
		this.adDiv = adDiv;
		this.wrapperMarginTop = wrapperMarginTop;
		this.myLog = logger('AdUnitClose:' + adDiv.id, this.config.verbosity);
		this.myLog(LEVEL.FLOW, `collapse toggle is enabled; appending toggle HTML to template`);
		let wrapper = this.wrapper = document.createElement('div');
		wrapper.classList.add(this.baseClass+'_wrap');
		wrapper.innerHTML = `<div class="${this.baseClass}_button">`+
			`<button class="${this.baseClass}_closead_button">${this.closeButtonLabel}<span class="ss-icon ss-delete ${this.baseClass}_closead_icon"></span></button>`+
			`<button class="${this.baseClass}_showad_button"">${this.showButtonLabel}<span class="ss-icon ss-navigateup ${this.baseClass}_showad_icon"></span></button>`+
		`</div>`;

		this.buttonWrapper = wrapper.querySelector('.'+this.baseClass+'_button');
		this.closeButton = wrapper.querySelector('.'+this.baseClass+'_closead_button');
		this.closeButton.addEventListener('click',() => {
			this.closeButtonHandler();
		});
		this.showButton = wrapper.querySelector('.'+this.baseClass+'_showad_button');
		this.showButton.addEventListener('click', () => {
			this.closeButtonHandler();
		});
		this.closeButtonIcon = wrapper.querySelector('.'+this.baseClass+'_closead_icon');
		this.showButtonIcon = wrapper.querySelector('.'+this.baseClass+'_showad_icon');

		this.setButtonStyles();

		adUnitDiv.parentNode.insertBefore(wrapper, adUnitDiv);
		wrapper.appendChild(adUnitDiv);
	}

	setButtonStyles() {
		// Set display to none for both buttons initially
		this.showButton.style.display = 'none';
		this.closeButton.style.display = 'none';

		this.wrapper.style.overflow = 'hidden';

		// Style the buttons (text of the button minus the icon)
		this.closeButton.style.fontSize = this.showButton.style.fontSize = '11px';
		this.closeButton.style.fontFamily = this.showButton.style.fontFamily ='arial';
		this.closeButton.style.fontWeight = this.showButton.style.fontWeight ='bold';
		this.closeButton.style.color = this.showButton.style.color = document.querySelector('body.theme-dark') ? '#FFFFFF' : '#333333';
		this.closeButton.style.paddingTop = this.showButton.style.paddingTop = '5px';
		this.closeButton.style.border = this.showButton.style.border = 'none';
		this.closeButton.style.setProperty('background', 'none', 'important');
		this.showButton.style.setProperty('background', 'none', 'important');

		// Styles the icons
		this.closeButtonIcon.style.fontSize = this.showButtonIcon.style.fontSize = '14px';
		this.closeButtonIcon.style.fontWeight = this.showButtonIcon.style.fontSize = 'bold';
	}

	setPosition(cb) {
		if (this.setPositionPending) {
			return;
		}
		this.setPositionPending = setTimeout(() => {
			let wrapperRect = this.wrapper.getBoundingClientRect();
			let boundingRect = this.adDiv.querySelector('iframe').getBoundingClientRect();
			let mobileMargin = document.querySelector('body.phone > div.l-page-container') ? document.querySelector('.l-page-container').getBoundingClientRect() : {};
			wrapperRect.width = (mobileMargin && mobileMargin.width) ? mobileMargin.width : wrapperRect.width;
			this.buttonWrapper.style.left = wrapperRect.width - (wrapperRect.width - boundingRect.width) / 2 - 65 + 'px';
			this.buttonWrapper.style.position = 'relative';
			this.setPositionPending = null;
			if (cb) {
				return cb();
			}
		}, 400);
	}

	setupButton() {
		this.dispatchEvent(this.name + ':show');
		this.hasAdRendered = true;
		if (!this.setup) {
			this.setup = true;
			window.addEventListener('resize',() => {
				this.setPosition();
			});

			window.addEventListener('orientationchanged',() => {
				this.setPosition();
			});
			this.setPosition(() => {
				this.closeButton.style.display = 'block';
			});
		} else {
			this.setPosition();
		}
	}

	hideButton() {
		this.closeButton.style.display = 'none';
		window.dispatchEvent(new Event(this.name + ':close'));
	}

	closeButtonHandler() {

		if (this.showButton.style.display === 'block') {
			//Show the ad
			this.adDiv.style.height = 'auto';
			this.adDiv.style.overflow = this.orig_overflow;
			this.showButton.style.display = 'none';
			this.closeButton.style.display = 'block';
			this.wrapper.style.marginTop = this.margin_top;
			this.dispatchEvent(this.name + ':show');
		} else {
			//Close the ad
			this.orig_overflow = this.adDiv.style.overflow;
			this.margin_top = this.wrapper.parentNode.style.marginTop || '0px';
			this.wrapper.style.marginTop = this.wrapperMarginTop || '0px';
			this.adDiv.style.height = '0px';
			this.adDiv.style.overflow = 'hidden';
			this.showButton.style.display = 'block';
			this.closeButton.style.display = 'none';
			this.dispatchEvent(this.name + ':close');
		}
	}

	dispatchEvent(eventName) {
		let event = document.createEvent('Event');
		event.initEvent(eventName,true,true);
		window.dispatchEvent(event);
	}
}
