import logger, { LEVEL } from '../components/logger';

let myLog;
let provider;
let providerConfig;
let slots = [];

export default function initialize(name, cfg) {
	myLog = logger('TargetingProvider:' + name, cfg.verbosity);
	myLog(LEVEL.FLOW, 'Initializing');

	providerConfig = cfg.targetingProviders[name];
	provider = name;

	//Inject amazon A9 js
	if (window && window.apstag) {
		myLog(LEVEL.FLOW, 'Amazon A9 library already loaded');
	} else {
		let amazon_script = document.createElement('script');
		amazon_script.type = 'text/javascript';
		amazon_script.id = 'amazon_script_loaded';
		amazon_script.async = true;
		amazon_script.src = providerConfig.amazon_script_uri;
		amazon_script.onload = function () {
			myLog(LEVEL.FLOW, 'Amazon A9 library finished parsing and executing');
			try {
				if (apstag && apstag.init && typeof apstag.init === 'function') {
					apstag.init({
						pubID: providerConfig.publisher_id,
						adServer: 'googletag'
					});
				}
			} catch (e) {}
		};
		let node = document.getElementsByTagName('script')[0];
		node.parentNode.insertBefore(amazon_script, node);
	}

	return {
		attachUnit,
		run
	};

}

function attachUnit(unit) {
	let slotDivId = unit.slot.getSlotElementId();
	let slot = {
		slotID: slotDivId,
		slotName: unit.path,
		sizes: unit.sizes
	};
	slots.push(slot);
}

function run(request) {
	try {
		if (apstag  && apstag.fetchBids && typeof apstag.fetchBids === 'function') {
			let amznConfig = {
				slots,
				timeout: providerConfig.timeout
			};
			apstag.fetchBids(amznConfig, (bids) => {
				setAmazonTargeting(bids, request);
			});
		}
	} catch (e) {}
}

function setAmazonTargeting(bids, request) {
	googletag.cmd.push(() => {
		try {
			apstag.setDisplayBids();
		} catch (e) {}
	});
	request.handleProviderFinished(provider);
}
