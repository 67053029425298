import Request from './TargetingProviderRequest';
import targeting_parameters from '../targetingproviders/targeting_parameters';
import amazon_A9 from '../targetingproviders/amazon_A9';
import rubicon_fastlane from '../targetingproviders/rubicon_fastlane';
import prebid_bidders from '../targetingproviders/prebid_bidders';
import geoedge from '../targetingproviders/geoedge';
import liveramp from '../targetingproviders/liveramp';
import nextroll from '../targetingproviders/nextroll';
import verizon_connectID from '../targetingproviders/verizon_connectID';
import test_provider from '../targetingproviders/test_provider';

import logger, { LEVEL } from './logger';

/**
 * Sadlib Test Mode Params:
 * localStorage.sadlibTestModeParams = `{
 *   "class":"TargetingProviders",
 *   "useTestProvider":true,
 *   "delayFinished": 500,
 *   "theseProvidersOnly":["prebid_bidders","test_provider"]
 * }`;
 *
 * useTestProvider: true or false
 * If useTestProvider is set to true, all real providers are removed and the test provider is used instead.
 * This is useful for testing if the this class is functioning as it should be.
 *
 * theseProvidersOnly: array of providers to use
 * You can specify an array of providers to use which can include any or all of the following providers.
 * This is useful for just testing a specific provider, or a specific combination of providers.
 * You can also use the test_provider in this array if you would like.
 *
 * delayFinished: ms
 * If you are using the test_provider, you can specify a delayFinished value which delay
 * calling request.handleProviderFinished for this many milliseconds. This only works on the
 * test_provider. This value has no effect for any other provider.
 */

let providers = {
	targeting_parameters,
	amazon_A9,
	rubicon_fastlane,
	prebid_bidders,
	geoedge,
	liveramp,
	nextroll,
	verizon_connectID,
	test_provider
};
let requests = [];
let attachedUnits = {};

/**
 * A list of targeting providers that have been removed. Where noted,
 * the configuration of some now removed targeting providers is still
 * used by other parts of Sadlib.
 * @constant
 * @type {string[]}
 */
const DEPRECATED_TARGETING_PROVIDERS = [
	'adomik',
	'epsilon_publinkID',
	'index_wrapper',
	'lotame_bidder',
	'smartwrapper'
];

export default class TargetingProviders {
	constructor(config, onFinished) {
		this.config = config;
		this.myLog = logger('TargetingProviders', this.config.verbosity);
		this.testMode = false;
		if (this.config.testMode && this.config.testModeParams.class.indexOf('TargetingProviders') > -1) {
			this.testMode = true;
			this.myLog(LEVEL.WARN, 'Test Mode Enabled');
		}

		//Setup individual targeting providers
		if (this.testMode) {
			if (this.config.testModeParams.useTestProvider) {
				this.config.targetingProviders = { test_provider: { enabled: true } };
			} else if (this.config.testModeParams.theseProvidersOnly) {
				Object.keys(this.config.targetingProviders).forEach((key) => this.config.testModeParams.theseProvidersOnly.indexOf(key) !== -1 || delete this.config.targetingProviders[key]);
			}
		}
		for (let name in this.config.targetingProviders) {
			if (DEPRECATED_TARGETING_PROVIDERS.includes(name)) {
				this.myLog(LEVEL.ERROR, `Skipping deprecated targeting provider "${name}"`);
			} else if (typeof providers[name] === 'function') {
				this.myLog(LEVEL.INFO, 'Configured Targeting Providers:', this.config.targetingProviders);
				if (this.config.targetingProviders[name].enabled) {
					this.myLog(LEVEL.FLOW, `initializing "${name}"`);
					//Setup the provider
					providers[name] = providers[name](name, this.config);
				} else {
					this.myLog(LEVEL.FLOW, `targeting provider "${name}" is disabled`);
					delete providers[name]; //Remove so we don't use it later
				}
			} else {
				this.myLog(LEVEL.WARN, `Not using unknown targeting provider "${name}"`);
			}
		}

		//Setup initial request
		this.initialRequest = new Request(this.config, onFinished, null, this.config.targetingProvidersTimeout || 500);
		this.eachProvider((name) => {
			this.initialRequest.addProvider(name);
		});
		requests.push(this.initialRequest);
	}

	//Setup a unit to be attached to a targeting provider.
	//This adds the unitName to an internal list of units, then
	//returns a callback that should be called when the unit has
	//its 'config.slot' object defined. It should be passed the unit object.
	//The callback will then actually attach the unit to each provider.
	//After all units have been fully attached, each providers run method will be called.
	attachUnit(unitName) {
		this.myLog(LEVEL.FLOW, 'will attach unit', unitName);
		if (!attachedUnits[unitName]) {
			attachedUnits[unitName] = false;
		}
		return (unit) => { //Return callback which is called when unit is defined.
			this._attachUnit(unit, true); //Attach unit to providers and run them if all units have been attached
			return (unit, cb) => { //Return callback which is called when unit is refreshed.
				this.refreshUnit(unit, cb); //Refresh a specific unit (that is already attached)
			};
		};
	}

	attachAndRefresh(unit, cb1) {
		this.myLog(LEVEL.FLOW, 'will attach and refresh unit', unit);
		return (unit) => {
			this._attachUnit(unit, false);
			this.refreshUnit(unit, cb1);
			return (unit, cb2) => {
				this.refreshUnit(unit, cb2);
			};
		};
	}

	_attachUnit(unit, initialRequest) {
		//By the time this method is called, all units will be initialized in gpt.
		//This is guaranteed due to the javascript stack and single threaded behavior.
		if (unit && unit.config && unit.config.slot && unit.config.name) {
			this.eachProvider((name, providerInst) => {
				if (typeof providerInst.attachUnit === 'function') {
					this.myLog(LEVEL.FLOW, `attaching ${unit.config.name} to ${name}`);
					providerInst.attachUnit(unit.config);
				}
			});
			attachedUnits[unit.config.name] = true;
			if (initialRequest) {
				this.initialRequest.addUnit(unit);
			}
		}
		if (initialRequest) {
			//Loop over units and see if each of them have been attached.
			//If so, call run.
			let run = true;
			// `attachedUnits` does not have `[Symbol.iterator]` so we have
			// to use `for ... in ...` and `Object.prototype.hasOwnProperty.call`
			// instead of `for ... of ...`
			for (let i in attachedUnits) {
				if (Object.prototype.hasOwnProperty.call(attachedUnits, i) && attachedUnits[i] !== true) {
					run = false;
					break;
				}
			}
			if (run) {
				this.myLog(LEVEL.INFO, 'All units have been attached, running providers.');
				this._run(this.initialRequest);
			}
		}
	}

	//Run the given request
	_run(request) {
		request.startTimer();
		this.eachProvider((name, providerInst) => {
			if (typeof providerInst.run === 'function') {
				this.myLog(LEVEL.DEBUG, `Calling run for ${name}...`);
				providerInst.run(request);
			} else {
				request.handleProviderFinished(name);
			}
		}, request.getProviders());
	}

	refreshUnit(unit, cb) {
		this.myLog(LEVEL.INFO, `refresh targeting providers for ${unit.config.name}`);
		let tpTimeout = this.config.targetingProvidersTimeout || 500;
		let tOut = this.config.refreshTimeout ? (tpTimeout + this.config.refreshTimeout) : tpTimeout;
		let request = new Request(this.config, cb, [unit], tOut);
		this.eachProvider((name, providerInst) => {
			if (typeof providerInst.run === 'function') {
				request.addProvider(name);
			}
		});
		requests.push(request);
		this._run(request);
	}

	//Set non-slot specific custom targeting attributes
	setPageLevelTargetingAttributes(client) {
		this.eachProvider((name, providerInst) => {
			if (typeof providerInst.setPageLevelTargetingAttributes === 'function') {
				this.myLog(LEVEL.INFO, `setting page level targeting for ${name}`);
				providerInst.setPageLevelTargetingAttributes(client);
			}
		});
	}

	//Helper function to loop over active targeting providers
	eachProvider(cb, customProviders, ...rest) {
		let providersList = customProviders || Object.keys(providers);
		//This is using 'providersList' as the keys, and the 'providers' object as the values.
		for (let i in providersList) {
			if (Object.prototype.hasOwnProperty.call(providersList, i) && Object.prototype.hasOwnProperty.call(providers, providersList[i]) && typeof providers[providersList[i]] !== 'function') {
				cb(providersList[i], providers[providersList[i]], cb, customProviders, ...rest); //eslint-disable-line callback-return
			}
		}
	}
}
